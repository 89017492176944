@charset "UTF-8";
/* webfont 変数　*/
/* ofi */
@media (min-width: 1753px) {
  /*====================================================================/
  /*--Large PC 以上--
  /====================================================================*/
}
@media (min-width: 1540px) {
  /*====================================================================/
  /*---PC 以上--
  /====================================================================*/
}
@media (min-width: 1176px) {
  /*====================================================================/
  /*--XGAサイズ　iPadPro 以上--
  /====================================================================*/
}
@media (min-width: 768px) {
  /*====================================================================/
  /*--タブレット　iPadmini 以上--
  /====================================================================*/
}
@media (min-width: 576px) {
  /*====================================================================/
  /*--スマホ 以上--
  /====================================================================*/
}
@media (min-width: 376px) {
  /*====================================================================/
  /*--iPhone 以上--
  /====================================================================*/
}
@media (max-width: 1752px) {
  /*====================================================================/
  /*--Large PC 未満--
  /====================================================================*/
}
@media (max-width: 1539px) {
  /*====================================================================/
  /*--PC 未満--
  /====================================================================*/
}
@media (max-width: 1175px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro 未満--
  /====================================================================*/
}
@media (max-width: 767px) {
  /*====================================================================/
  /*--タブレット iPadmini 未満--
  /====================================================================*/
}
@media (max-width: 575px) {
  /*====================================================================/
  /*--iPhone 以下--
  /====================================================================*/
}
@media (min-width: 576px) and (max-width: 767px) {
  /*====================================================================/
  /*--スマホ以上 タブレット iPadmini未満--
  /====================================================================*/
}
@media (min-width: 768px) and (max-width: 1175px) {
  /*====================================================================/
  /*--タブレット iPadmini以上 XGAサイズ iPadPro未満--
  /====================================================================*/
}
@media (min-width: 1176px) and (max-width: 1539px) {
  /*====================================================================/
  /*--XGAサイズ iPadPro以上 PC未満--
  /====================================================================*/
}
@media (min-width: 1540px) and (max-width: 1752px) {
  /*====================================================================/
  /*--px以上 Large PC未満--
  /====================================================================*/
}
/*====================================================================

index.css

=====================================================================*/
.main {
  overflow: hidden;
}

/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
.main-view {
  position: relative;
}
@media (min-width: 768px) {
  .main-view {
    height: 57.1vw;
  }
}
@media (min-width: 1176px) {
  .main-view {
    height: 100vh;
  }
}
@media (max-width: 767px) {
  .main-view {
    height: 150vw;
  }
}

.main-view__bg {
  position: relative;
  overflow: hidden;
}
@media (min-width: 768px) {
  .main-view__bg {
    height: 57.1vw;
  }
}
@media (min-width: 1176px) {
  .main-view__bg {
    height: 100vh;
  }
}
@media (max-width: 767px) {
  .main-view__bg {
    height: 150vw;
  }
}
.main-view__bg__inner {
  height: 100%;
}
.main-view__bg__img {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
@media (min-width: 768px) {
  .main-view__bg__img {
    height: 57.1vw;
  }
}
@media (min-width: 1176px) {
  .main-view__bg__img {
    height: 100vh;
  }
}
@media (max-width: 767px) {
  .main-view__bg__img {
    height: 150vw;
  }
}
.main-view__bg__img__inner {
  height: 100%;
}

.main-view__inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.main-view__inner .l-cont {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
}

.main-view__block {
  content: "";
  position: absolute;
  width: 100%;
  top: 0;
  right: 20%;
  z-index: 3;
  background: rgba(26, 64, 105, 0.9);
  width: 100vw;
  height: 130%;
  transform-origin: top right;
  transform: skew(-40deg);
}
@media (min-width: 768px) {
  .main-view__block {
    width: 50vw;
    right: 54%;
  }
}
@media (min-width: 1176px) {
  .main-view__block {
    background: #1a4069;
  }
}

.main-view__catch {
  position: relative;
  z-index: 4;
  color: #000000;
  padding-top: 7em;
}
@media (min-width: 1176px) {
  .main-view__catch {
    padding-top: 0;
    padding-right: 1rem;
  }
}
.main-view__catch .is-catch01, .main-view__catch .is-catch02, .main-view__catch .is-catch03 {
  display: block;
  margin-bottom: clamp(0.5rem, 1vw, 1rem);
}
.main-view__catch .is-catch01__inner, .main-view__catch .is-catch02__inner, .main-view__catch .is-catch03__inner {
  display: inline-block;
  position: relative;
  line-height: 1;
  font-family: 'Zen Kaku Gothic Antique', sans-serif;
  font-size: clamp(22px, 7.86vw, 86px);
  font-weight: 700;
  letter-spacing: 0.07em;
}
@media (min-width: 768px) {
  .main-view__catch .is-catch01__inner, .main-view__catch .is-catch02__inner, .main-view__catch .is-catch03__inner {
    font-size: clamp(42px, 5.47vw, 72px);
  }
}
@media (min-width: 1540px) {
  .main-view__catch .is-catch01__inner, .main-view__catch .is-catch02__inner, .main-view__catch .is-catch03__inner {
    font-size: 4.78rem;
  }
}
.main-view__catch .is-catch01__inner.is-active::before, .main-view__catch .is-catch02__inner.is-active::before, .main-view__catch .is-catch03__inner.is-active::before {
  width: 100%;
}
.main-view__catch .is-catch01__inner::before, .main-view__catch .is-catch02__inner::before, .main-view__catch .is-catch03__inner::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 0;
  height: 100%;
  background: #ffffff;
  z-index: -1;
  transition: all 0.3s ease 0s;
}
.main-view__catch .is-catch01__letter, .main-view__catch .is-catch02__letter, .main-view__catch .is-catch03__letter {
  display: inline-block;
  line-height: 1em;
}
.main-view__catch .is-catch04 {
  display: block;
}
.main-view__catch .is-catch04__inner {
  display: inline-block;
  position: relative;
  padding-top: 0.5em;
  padding-left: 0.3em;
  line-height: 1;
  font-weight: 700;
  font-family: 'Red Hat Display', sans-serif;
  font-size: clamp(10px, 3.57vw, 30px);
  letter-spacing: 0.12em;
  color: #1A4069;
}
@media (min-width: 768px) {
  .main-view__catch .is-catch04__inner {
    font-size: clamp(14px, 1.82vw, 35px);
  }
}
@media (min-width: 1921px) {
  .main-view__catch .is-catch04__inner {
    font-size: 1.94rem;
  }
}
.main-view__catch .is-catch04__inner.is-active::before {
  width: 100%;
}
.main-view__catch .is-catch04__inner::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 0;
  height: 100%;
  background: #ffffff;
  z-index: -1;
  transition: all 0.3s ease 0s;
}
.main-view__catch .is-catch04__letter {
  display: inline-block;
  line-height: 1em;
}

/* scroll */
.scroll-line {
  position: absolute;
  left: min(1.5%, 3rem);
  bottom: 0;
  writing-mode: vertical-rl;
  z-index: 4;
}
.scroll-line a {
  text-decoration: none !important;
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 500;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #ffffff;
  transition: all 0.3s ease 0s;
}
.scroll-line a:hover {
  color: #6398BE;
}
.scroll-line span {
  content: "";
  inline-size: 5rem;
  block-size: 1px;
  background: transparent;
  display: block;
  position: relative;
  overflow: hidden;
}
.scroll-line span::before {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  background: #ffffff;
  transition: all 0.3s ease 0s;
  block-size: 1px;
  inline-size: 100%;
  animation: scroll-line 1.75s cubic-bezier(1, 0, 0, 1) infinite;
}

@keyframes scroll-line {
  0% {
    transform: scale(1, 0);
    transform-origin: 0 0;
  }
  50% {
    transform: scale(1, 1);
    transform-origin: 0 0;
  }
  51% {
    transform: scale(1, 1);
    transform-origin: 0 100%;
  }
  100% {
    transform: scale(1, 0);
    transform-origin: 0 100%;
  }
}
/* common
----------------------------------------------------------------*/
/*--------------------------------------------------------------------/
	contents
/--------------------------------------------------------------------*/
.contents {
  background: #ffffff;
}

/*--------------------------------------------------------------------/
	aboutu us
/--------------------------------------------------------------------*/
.top-aboutus {
  position: relative;
  overflow: hidden;
  padding-top: 8rem;
  padding-bottom: clamp(4rem, 8vw, 8rem);
}
@media (min-width: 768px) {
  .top-aboutus {
    padding-top: 12rem;
  }
}
@media (min-width: 1176px) {
  .top-aboutus {
    padding-top: clamp(4rem, 5vw, 5rem);
  }
}
.top-aboutus__block {
  position: absolute;
  left: 0;
  bottom: 0;
  content: "";
  background: #EFF5FB;
}
@media (min-width: 1176px) {
  .top-aboutus__block {
    width: 87.5%;
    height: calc(100% - 9.5vw);
  }
}
@media (min-width: 1540px) {
  .top-aboutus__block {
    height: calc(100% - 9em);
  }
}
@media (max-width: 1175px) {
  .top-aboutus__block {
    width: 100%;
    height: calc(100% - 30vw);
  }
}
.top-aboutus__inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 1536px;
  position: relative;
  display: flex;
  justify-content: space-between;
  z-index: 2;
}
@media (min-width: 1176px) {
  .top-aboutus__inner {
    column-gap: 0.57%;
    padding-left: 48px;
    padding-right: 7.22%;
  }
}
@media (min-width: 1921px) {
  .top-aboutus__inner {
    padding-right: 4%;
  }
}
@media (max-width: 1175px) {
  .top-aboutus__inner {
    flex-direction: column-reverse;
    row-gap: clamp(1.5em, 2vw, 2rem);
    padding-left: 8%;
    padding-right: 8%;
  }
}
@media (max-width: 767px) {
  .top-aboutus__inner {
    padding-left: 5%;
    padding-right: 5%;
  }
}

@media (min-width: 1176px) {
  .top-aboutus__txt {
    width: 55%;
    display: flex;
    justify-content: flex-end;
  }
}
@media (min-width: 1540px) {
  .top-aboutus__txt__inner {
    padding-left: 0;
  }
}
@media (min-width: 1921px) {
  .top-aboutus__txt__inner {
    width: 42.25vw;
  }
}
.top-aboutus__txt__tit {
  margin-bottom: clamp(2rem, 3vw, 3rem);
  line-height: 1.2;
}
.top-aboutus__txt__tit em {
  font-size: calc(2rem + 70 * (100vw - 280px) / 1360);
  display: block;
  font-style: normal;
  font-weight: 700;
  font-family: 'Red Hat Display', sans-serif;
  color: #1A4069;
}
@media (min-width: 1753px) {
  .top-aboutus__txt__tit em {
    font-size: 5.56rem;
  }
}
.top-aboutus__txt__tit b {
  font-size: calc(1rem + 14 * (100vw - 280px) / 1360);
  font-weight: 700;
  display: block;
  margin-top: 0.2em;
}
@media (min-width: 1753px) {
  .top-aboutus__txt__tit b {
    font-size: 1.67rem;
  }
}
.top-aboutus__txt__txt strong {
  font-weight: 700;
  font-size: 1.14em;
  line-height: 1.5;
}
@media (min-width: 768px) {
  .top-aboutus__txt__txt strong {
    font-size: 1.25em;
  }
}
@media (min-width: 1540px) {
  .top-aboutus__txt__txt {
    line-height: 2.2;
    font-size: 1.075em;
  }
}

.top-aboutus__img {
  position: relative;
  width: 60%;
}
@media (min-width: 1176px) {
  .top-aboutus__img {
    margin-top: 9.5em;
    max-width: 380px;
  }
}
@media (min-width: 1540px) {
  .top-aboutus__img {
    margin-top: 12.5em;
    max-width: 425px;
  }
}
@media (max-width: 1175px) {
  .top-aboutus__img {
    max-width: 300px;
  }
}
.top-aboutus__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
}
.top-aboutus__img__inner::before {
  content: "";
  display: block;
  padding-top: 75%;
  position: relative;
}
.top-aboutus__img__inner > div,
.top-aboutus__img__inner figure,
.top-aboutus__img__inner a,
.top-aboutus__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.top-aboutus__img img {
  width: 100%;
  display: block;
}

.top-aboutus__bg {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
}
@media (min-width: 1176px) {
  .top-aboutus__bg {
    width: 44.43%;
  }
}
@media (max-width: 1175px) {
  .top-aboutus__bg {
    width: 80%;
  }
}
.top-aboutus__bg__inner {
  position: relative;
  width: 100%;
  z-index: 1;
}
.top-aboutus__bg__inner::before {
  content: "";
  display: block;
  padding-top: 45.36%;
  position: relative;
}
.top-aboutus__bg__inner > div,
.top-aboutus__bg__inner figure,
.top-aboutus__bg__inner a,
.top-aboutus__bg__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.top-aboutus__bg img {
  width: 100%;
  display: block;
}

/*--------------------------------------------------------------------/
	pickup 01
/--------------------------------------------------------------------*/
.pickup01 {
  position: relative;
  padding-top: clamp(8rem, 16vw, 16rem);
  padding-bottom: clamp(6rem, 7vw, 7rem);
  margin-bottom: clamp(6rem, 10vw, 10rem);
  background: url(../img/top/pickup01-bg.png) no-repeat 50% 0;
}
@media (min-width: 1540px) {
  .pickup01 {
    background-size: 100%;
  }
}
.pickup01-tit {
  line-height: 1;
  text-align: center;
  margin-bottom: clamp(3rem, 6vw, 6rem);
}
.pickup01-tit em {
  display: block;
  margin-top: 1em;
  font-style: normal;
  font-size: calc(1rem + 10 * (100vw - 280px) / 1360);
  font-weight: 500;
  font-family: 'Red Hat Display', sans-serif;
  letter-spacing: 0.2em;
  color: #1A4069;
}
@media (min-width: 1753px) {
  .pickup01-tit em {
    font-size: 1.33rem;
  }
}
.pickup01-tit b {
  display: block;
  font-size: calc(1.5rem + 20 * (100vw - 280px) / 1360);
  font-weight: 700;
}
@media (min-width: 1540px) {
  .pickup01-tit b {
    letter-spacing: 0.075em;
    font-size: 2.78rem;
  }
}
.pickup01-inner {
  position: relative;
  z-index: 2;
}
.pickup01-btn {
  margin-top: clamp(4rem, 5vw, 5rem);
}
.pickup01::before {
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 80%;
  background-color: #F3FAFF;
  content: "";
  z-index: 1;
}
@media (min-width: 768px) {
  .pickup01::before {
    height: 70%;
  }
}
@media (min-width: 1176px) {
  .pickup01::before {
    height: 55%;
  }
}
@media (min-width: 1753px) {
  .pickup01::before {
    height: 50%;
  }
}

.pickup01-intro {
  margin-bottom: clamp(5rem, 8vw, 8rem);
}
.pickup01-intro__flex {
  display: flex;
}
@media (min-width: 1176px) {
  .pickup01-intro__flex {
    justify-content: space-around;
  }
  .pickup01-intro__flex > * {
    flex-basis: 40.5%;
  }
}
@media (max-width: 1175px) {
  .pickup01-intro__flex {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}
@media (min-width: 1176px) {
  .pickup01-intro__flex .pickup01-intro__box__tit {
    font-size: calc(1.5rem + 10 * (100vw - 1080px) / 560);
  }
}
@media (min-width: 1540px) {
  .pickup01-intro__flex .pickup01-intro__box__tit {
    font-size: 2rem;
  }
}

.pickup01-intro__box__inner {
  width: 100%;
  padding-left: 10%;
  position: relative;
}
.pickup01-intro__box__tit {
  font-weight: 700;
  padding-top: clamp(2rem, 4vw, 4rem);
  margin-bottom: calc(1rem + 0.5em);
  font-size: calc(1rem + 24 * (100vw - 280px) / 1360);
}
@media (min-width: 1753px) {
  .pickup01-intro__box__tit {
    font-size: 2.22rem;
  }
}
.pickup01-intro__box__tit::before {
  position: absolute;
  top: 0;
  left: -1.25em;
  content: "";
  width: 4em;
  height: 1px;
  background: #1A4069;
  transform-origin: top right;
  transform: rotate(-40deg);
}

.pickup01-list2 {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  row-gap: clamp(3rem, 5vw, 5rem);
}
@media (min-width: 576px) {
  .pickup01-list2 {
    flex-wrap: wrap;
    column-gap: 4%;
    max-width: calc(800px + 4%);
  }
}
@media (min-width: 768px) {
  .pickup01-list2 {
    column-gap: 8%;
    max-width: calc(800px + 8%);
  }
}
@media (min-width: 1176px) {
  .pickup01-list2 {
    column-gap: 4%;
    max-width: 100%;
    row-gap: clamp(3rem, 7vw, 7rem);
  }
}
@media (min-width: 1540px) {
  .pickup01-list2 {
    column-gap: 8%;
  }
}
@media (max-width: 575px) {
  .pickup01-list2 {
    flex-direction: column;
  }
}
@media (min-width: 576px) {
  .pickup01-list2 > * {
    width: 48%;
  }
}
@media (min-width: 768px) {
  .pickup01-list2 > * {
    width: 46%;
  }
}
@media (min-width: 1176px) {
  .pickup01-list2 > * {
    width: 48%;
  }
}
@media (min-width: 1540px) {
  .pickup01-list2 > * {
    width: 46%;
  }
}

.pickup01-list3 {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  row-gap: clamp(3rem, 5vw, 5rem);
}
@media (min-width: 576px) {
  .pickup01-list3 {
    flex-wrap: wrap;
    column-gap: 4%;
  }
}
@media (min-width: 768px) {
  .pickup01-list3 {
    column-gap: 6%;
  }
}
@media (min-width: 1176px) {
  .pickup01-list3 {
    column-gap: 1.5%;
  }
}
@media (min-width: 1540px) {
  .pickup01-list3 {
    column-gap: 8.33%;
    max-width: 100%;
  }
}
@media (max-width: 575px) {
  .pickup01-list3 {
    flex-direction: column;
  }
}
@media (min-width: 576px) {
  .pickup01-list3 > * {
    width: 48%;
  }
}
@media (min-width: 768px) {
  .pickup01-list3 > * {
    width: 47%;
  }
}
@media (min-width: 1176px) {
  .pickup01-list3 > * {
    width: 32.33%;
  }
}
@media (min-width: 1540px) {
  .pickup01-list3 > * {
    width: 27.78%;
  }
}

.pickup01-list4 {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  row-gap: clamp(3rem, 5vw, 5rem);
}
@media (min-width: 576px) {
  .pickup01-list4 {
    flex-wrap: wrap;
    column-gap: 4%;
    max-width: calc(800px + 4%);
  }
}
@media (min-width: 768px) {
  .pickup01-list4 {
    column-gap: 8%;
    max-width: calc(800px + 8%);
  }
}
@media (min-width: 1540px) {
  .pickup01-list4 {
    column-gap: 3.48%;
    max-width: 100%;
  }
}
@media (max-width: 575px) {
  .pickup01-list4 {
    flex-direction: column;
  }
}
@media (min-width: 576px) {
  .pickup01-list4 > * {
    width: 48%;
  }
}
@media (min-width: 768px) {
  .pickup01-list4 > * {
    width: 46%;
  }
}
@media (min-width: 1540px) {
  .pickup01-list4 > * {
    width: 22.39%;
  }
}

@media (max-width: 1920px) {
  .pickup01-box {
    max-width: 400px;
  }
}
.pickup01-box__inner {
  display: flex;
  flex-direction: column;
}
.pickup01-box__tit {
  order: 2;
  margin-top: 1.4em;
  line-height: 1.4;
  padding-bottom: calc(0.5rem + 0.3em);
  border-bottom: 1px solid #C9CED2;
  position: relative;
  color: #1A4069;
  font-weight: 700;
  font-size: calc(1.2rem + 1.5 * (100vw - 376px) / 1264);
}
@media (min-width: 768px) {
  .pickup01-box__tit {
    letter-spacing: 0.02em;
  }
}
@media (min-width: 1176px) {
  .pickup01-box__tit {
    letter-spacing: 0.05em;
    font-size: calc(1rem + 3 * (100vw - 1080px) / 560);
  }
}
@media (min-width: 1540px) {
  .pickup01-box__tit {
    font-size: 1.22rem;
  }
}
.pickup01-box__tit::after {
  position: absolute;
  bottom: -1px;
  left: 0;
  content: "";
  background-color: #1A4069;
  width: 60px;
  height: 1px;
}
@media (min-width: 576px) {
  .pickup01-list4 .pickup01-box__tit {
    font-size: calc(1rem + 8 * (100vw - 576px) / 1064);
  }
}
@media (min-width: 1540px) {
  .pickup01-list4 .pickup01-box__tit {
    font-size: calc(1.14rem + 2 * (100vw - 1440px) / 200);
  }
}
@media (min-width: 1753px) {
  .pickup01-list4 .pickup01-box__tit {
    font-size: 1.11rem;
  }
}
.pickup01-box__img {
  order: 1;
  width: 100%;
}
.pickup01-box__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: 0;
}
.pickup01-box__img__inner::before {
  content: "";
  display: block;
  padding-top: 56.25%;
  position: relative;
}
.pickup01-box__img__inner > div,
.pickup01-box__img__inner figure,
.pickup01-box__img__inner a,
.pickup01-box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
.pickup01-box__txt {
  order: 3;
  margin-top: 1em;
}
.pickup01-box .btn-wrap {
  margin-top: 1.5em;
}
.pickup01-box__btn {
  display: block;
  text-align: center;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  color: #ffffff;
  background-color: #000000;
  border: 1px solid;
  padding: 0.75em 4%;
  width: 100%;
  border-radius: 999px;
}
@media (min-width: 1753px) {
  .pickup01-box__btn {
    font-size: 24px;
  }
}
.pickup01-box__btn:after {
  content: "\e315";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pickup01-box__btn:hover {
  background-color: #1A4069;
}
.pickup01-box__btn::after {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  color: #ffffff;
}

/*--------------------------------------------------------------------/
	pickup 02
/--------------------------------------------------------------------*/
.pickup02-wrap {
  position: relative;
  overflow: hidden;
  padding-top: clamp(2rem, 4vw, 4rem);
  margin-bottom: clamp(4rem, 7vw, 7rem);
}
.pickup02-wrap::before {
  content: "";
  position: absolute;
  width: 80%;
  height: 74%;
}
@media (min-width: 768px) {
  .pickup02-wrap::before {
    height: 86%;
  }
}
@media (min-width: 1176px) {
  .pickup02-wrap::before {
    height: 90%;
  }
}
@media (min-width: 1540px) {
  .pickup02-wrap::before {
    height: 86%;
    width: 80.21%;
  }
}
.pickup02-inner {
  position: relative;
  width: 100%;
  z-index: 2;
  padding: clamp(3rem, 7vw, 7rem) 0 clamp(4rem, 9vw, 9rem);
}
@media (min-width: 1176px) {
  .pickup02-inner {
    padding: clamp(4rem, 7vw, 7rem) 0 clamp(4rem, 8vw, 8rem);
  }
}
@media (min-width: 1540px) {
  .pickup02-inner {
    min-height: 900px;
  }
}
.pickup02 > *:nth-of-type(odd) .pickup02-wrap::before {
  top: 0;
  left: 0;
  background: #1A4069;
}
.pickup02 > *:nth-of-type(odd) .pickup02-img {
  right: 0;
}
@media (max-width: 1175px) {
  .pickup02 > *:nth-of-type(odd) .pickup02-img {
    display: flex;
    flex-direction: row-reverse;
  }
}
.pickup02 > *:nth-of-type(odd) .pickup02-txt__tit em {
  color: #1A4069;
}
.pickup02 > *:nth-of-type(odd) .pickup02-txt__tit b::before {
  background: #1A4069;
}
.pickup02 > *:nth-of-type(odd) .pickup02-txt__catch strong::before {
  background: #1A4069;
}
.pickup02 > *:nth-of-type(odd) .pickup02-txt__btn {
  display: block;
  text-align: center;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 0.5em;
  align-items: center;
  color: #ffffff;
  background-color: #1A4069;
  border: 1px solid;
  border-color: #1A4069;
  padding: 1.18em 4%;
  width: 100%;
  border-radius: 999px;
}
@media (min-width: 1753px) {
  .pickup02 > *:nth-of-type(odd) .pickup02-txt__btn {
    font-size: 24px;
  }
}
.pickup02 > *:nth-of-type(odd) .pickup02-txt__btn:after {
  content: "\e901";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pickup02 > *:nth-of-type(odd) .pickup02-txt__btn:hover {
  color: #ffffff;
  background-color: #31608C;
  border-color: #31608C;
}
.pickup02 > *:nth-of-type(odd) .pickup02-txt__btn:hover::after {
  color: #ffffff;
}
.pickup02 > *:nth-of-type(odd) .pickup02-txt__btn::before {
  content: "";
  justify-self: start;
}
.pickup02 > *:nth-of-type(odd) .pickup02-txt__btn::after {
  justify-self: end;
  font-size: 1.5em;
}
.pickup02 > *:nth-of-type(even) .pickup02-wrap::before {
  top: 0;
  right: 0;
  background: #6398BE;
}
.pickup02 > *:nth-of-type(even) .pickup02-img {
  left: 0;
}
.pickup02 > *:nth-of-type(even) .pickup02-txt__tit em {
  color: #6398BE;
}
.pickup02 > *:nth-of-type(even) .pickup02-txt__tit b::before {
  background: #6398BE;
}
.pickup02 > *:nth-of-type(even) .pickup02-txt__catch strong::before {
  background: #6398BE;
}
.pickup02 > *:nth-of-type(even) .l-cont {
  display: flex;
  flex-direction: row-reverse;
}
.pickup02 > *:nth-of-type(even) .pickup02-txt__btn {
  display: block;
  text-align: center;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 0.5em;
  align-items: center;
  color: #ffffff;
  background-color: #6398BE;
  border: 1px solid;
  border-color: #6398BE;
  padding: 1.18em 4%;
  width: 100%;
  border-radius: 999px;
}
@media (min-width: 1753px) {
  .pickup02 > *:nth-of-type(even) .pickup02-txt__btn {
    font-size: 24px;
  }
}
.pickup02 > *:nth-of-type(even) .pickup02-txt__btn:after {
  content: "\e901";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pickup02 > *:nth-of-type(even) .pickup02-txt__btn:hover {
  color: #ffffff;
  background-color: #1A4069;
  border-color: #1A4069;
}
.pickup02 > *:nth-of-type(even) .pickup02-txt__btn:hover::after {
  color: #ffffff;
}
.pickup02 > *:nth-of-type(even) .pickup02-txt__btn::before {
  content: "";
  justify-self: start;
}
.pickup02 > *:nth-of-type(even) .pickup02-txt__btn::after {
  justify-self: end;
  font-size: 1.5em;
}

@media (min-width: 1176px) {
  .pickup02-img {
    position: absolute;
    top: 0;
    width: 60%;
    height: 100%;
  }
}
@media (min-width: 1540px) {
  .pickup02-img {
    width: 44.79%;
  }
}
@media (min-width: 1176px) {
  .pickup02-img__wrap {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 1175px) {
  .pickup02-img__wrap {
    width: 85%;
  }
}
@media (min-width: 1176px) {
  .pickup02-img__inner {
    margin: 0;
    position: relative;
    width: 100%;
    height: 100%;
  }
  .pickup02-img__inner div {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 1175px) {
  .pickup02-img__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .pickup02-img__inner::before {
    content: "";
    display: block;
    padding-top: 66.66%;
    position: relative;
  }
  .pickup02-img__inner > div,
  .pickup02-img__inner figure,
  .pickup02-img__inner a,
  .pickup02-img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}

.pickup02-txt {
  width: 100%;
  background: #ffffff;
  position: relative;
  z-index: 2;
  overflow: hidden;
}
@media (min-width: 768px) {
  .pickup02-txt {
    width: 90%;
  }
}
@media (min-width: 1176px) {
  .pickup02-txt {
    width: 67%;
  }
}
@media (max-width: 1175px) {
  .pickup02-txt {
    margin-top: clamp(-3rem, -9vw, -8rem);
  }
}
.pickup02-txt__inner {
  padding: clamp(2em, 3vw, 3rem) 7% clamp(2em, 3vw, 3rem) 8%;
}
@media (min-width: 1540px) {
  .pickup02-txt__inner {
    padding: clamp(2em, 5vw, 5.5rem) 6% clamp(2em, 5vw, 5.5rem) 12%;
  }
}
@media (min-width: 1753px) {
  .pickup02-txt__inner .txt {
    font-size: 1.06rem;
  }
}
.pickup02-txt__tit {
  line-height: 1.2;
  margin-bottom: clamp(1em, 2vw, 2rem);
}
.pickup02-txt__tit em {
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 500;
  letter-spacing: 0.12;
  font-style: normal;
  display: block;
  font-size: calc(1rem + 16 * (100vw - 280px) / 1360);
  margin-top: 0.4em;
}
@media (min-width: 768px) {
  .pickup02-txt__tit em {
    font-size: calc(1.5rem + 6 * (100vw - 768px) / 872);
  }
}
@media (min-width: 1540px) {
  .pickup02-txt__tit em {
    font-size: 1.22rem;
  }
}
.pickup02-txt__tit b {
  position: relative;
  font-family: 'Zen Kaku Gothic Antique', sans-serif;
  font-weight: 700;
  display: block;
  letter-spacing: 0.075;
  font-size: calc(1.45rem + 30 * (100vw - 280px) / 1360);
}
@media (min-width: 768px) {
  .pickup02-txt__tit b {
    font-size: calc(2.2rem + 12 * (100vw - 768px) / 872);
  }
}
@media (min-width: 1753px) {
  .pickup02-txt__tit b {
    font-size: 2.78rem;
  }
}
.pickup02-txt__tit b::before {
  position: absolute;
  top: 0.6em;
  right: calc(100% + 0.3em);
  content: "";
  width: 100%;
  height: 1px;
}
.pickup02-txt__catch {
  margin-bottom: clamp(0.5em, 1vw, 0.75em);
  font-size: calc(1rem + 6 * (100vw - 280px) / 1360);
  line-height: 1.5;
}
@media (min-width: 768px) {
  .pickup02-txt__catch {
    font-size: calc(1rem + 4 * (100vw - 768px) / 872);
  }
}
@media (min-width: 1540px) {
  .pickup02-txt__catch {
    font-size: 1.11rem;
  }
}
.pickup02-txt__catch strong {
  position: relative;
  color: #000000;
  padding-right: 0.5em;
}

/*--------------------------------------------------------------------/
	top works
/--------------------------------------------------------------------*/
.top-works {
  margin-top: clamp(6rem, 13vw, 13rem);
  padding-top: clamp(4rem, 7vw, 7rem);
  padding-bottom: clamp(6rem, 10vw, 12rem);
  position: relative;
  overflow: hidden;
}
.top-works__bg {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 60%;
  max-height: 520px;
  z-index: 1;
}
@media (min-width: 1540px) {
  .top-works__bg {
    width: 98.5%;
  }
}
@media (min-width: 1753px) {
  .top-works__bg {
    width: 95%;
  }
}
.top-works__bg__inner {
  position: relative;
  max-height: 520px;
  overflow: hidden;
}
.top-works__bg__inner::after {
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.23);
  z-index: 2;
}
.top-works__inner {
  position: relative;
  z-index: 3;
}
.top-works__tit {
  color: #ffffff;
  margin-bottom: clamp(2rem, 3vw, 3rem);
}
.top-works__tit em {
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 500;
  letter-spacing: 0.3em;
  font-style: normal;
  display: block;
  font-size: calc(1rem + 16 * (100vw - 280px) / 1360);
}
@media (min-width: 768px) {
  .top-works__tit em {
    font-size: calc(1.5rem + 6 * (100vw - 768px) / 872);
  }
}
@media (min-width: 1540px) {
  .top-works__tit em {
    font-size: 1.78rem;
  }
}
.top-works__tit b {
  font-family: 'Zen Kaku Gothic Antique', sans-serif;
  font-weight: 700;
  display: block;
  letter-spacing: 0.075;
  font-size: calc(1.8rem + 30 * (100vw - 280px) / 1360);
}
@media (min-width: 768px) {
  .top-works__tit b {
    font-size: calc(2.2rem + 12 * (100vw - 768px) / 872);
  }
}
@media (min-width: 1753px) {
  .top-works__tit b {
    font-size: 3.89rem;
  }
}
.top-works__list .is-column {
  margin-bottom: clamp(2rem, 5vw, 5rem);
}
.top-works__btn {
  margin-left: auto;
  margin-right: auto;
}
.top-works__btn a {
  display: block;
  text-align: center;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 0.5em;
  align-items: center;
  color: #ffffff;
  background-color: #1A4069;
  border: 1px solid;
  border-color: #1A4069;
  padding: 1.18em 4%;
  width: 100%;
  border-radius: 999px;
}
@media (min-width: 1753px) {
  .top-works__btn a {
    font-size: 24px;
  }
}
.top-works__btn a:after {
  content: "\e901";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.top-works__btn a:hover {
  color: #ffffff;
  background-color: #6398BE;
  border-color: #6398BE;
}
.top-works__btn a:hover::after {
  color: #ffffff;
}
.top-works__btn a::before {
  content: "";
  justify-self: start;
}
.top-works__btn a::after {
  justify-self: end;
  font-size: 1.5em;
}
@media (max-width: 1175px) {
  .top-works__btn a {
    display: block;
    text-align: center;
    position: relative;
    line-height: 1.4;
    transition: all 0.3s ease 0s;
    text-decoration: none !important;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    column-gap: 0.5em;
    align-items: center;
    color: #ffffff;
    background-color: #1A4069;
    border: 1px solid;
    border-color: #1A4069;
    padding: 1.18em 4%;
    width: 100%;
    border-radius: 999px;
  }
}
@media (max-width: 1175px) and (min-width: 1753px) {
  .top-works__btn a {
    font-size: 24px;
  }
}
@media (max-width: 1175px) {
  .top-works__btn a:after {
    content: "\e901";
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .top-works__btn a:hover {
    color: #ffffff;
    background-color: #6398BE;
    border-color: #6398BE;
  }
  .top-works__btn a:hover::after {
    color: #ffffff;
  }
  .top-works__btn a::before {
    content: "";
    justify-self: start;
  }
  .top-works__btn a::after {
    justify-self: end;
    font-size: 1.5em;
  }
}

.top-works {
  position: relative;
}
.top-works::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 80%;
  background: #f6f6f6;
  height: 80%;
}
@media (min-width: 1176px) {
  .top-works::before {
    height: 70%;
    bottom: 0;
  }
}
.top-works .swiper {
  width: 100%;
  overflow: visible;
  position: relative;
  margin-bottom: 5rem;
}
@media (min-width: 1540px) {
  .top-works .swiper {
    margin-bottom: 7rem;
  }
}
.top-works .swiper-control {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 1440 px;
}
@media (min-width: 1921px) {
  .top-works .swiper-control {
    max-width: 75vw;
  }
}
.top-works .swiper-next_prev {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: calc(100% + 32px);
  left: -16px;
  top: 40%;
  z-index: 1;
  transform: translateY(-50%);
}
@media (min-width: 768px) {
  .top-works .swiper-next_prev {
    width: calc(100% + 64px);
    left: -32px;
  }
}
@media (min-width: 1176px) {
  .top-works .swiper-next_prev {
    width: calc(100% + 90px);
    left: -45px;
  }
}
.top-works .swiper-button-prev {
  left: 0;
}
.top-works .swiper-button-next {
  right: 0;
}
.top-works .swiper-button-prev,
.top-works .swiper-button-next {
  margin: 0;
  float: none;
  display: block;
  background-image: none;
  width: 48px;
  height: 48px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}
@media (min-width: 768px) {
  .top-works .swiper-button-prev,
  .top-works .swiper-button-next {
    width: 64px;
    height: 64px;
  }
}
@media (min-width: 1176px) {
  .top-works .swiper-button-prev,
  .top-works .swiper-button-next {
    width: 90px;
    height: 90px;
  }
}
.top-works .swiper-button-prev::before,
.top-works .swiper-button-next::before {
  content: "";
  position: absolute;
  background: #ffffff;
  border: 1px solid #ffffff;
  transition: all 0.3s ease 0s;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.top-works .swiper-button-prev::after,
.top-works .swiper-button-next::after {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #1A4069;
  font-size: 1.5em;
  transition: all 0.3s ease 0s;
  z-index: 3;
}
@media (min-width: 1176px) {
  .top-works .swiper-button-prev::after,
  .top-works .swiper-button-next::after {
    font-size: 2.67rem;
  }
}
.top-works .swiper-button-prev:hover::before,
.top-works .swiper-button-next:hover::before {
  background: #1A4069;
  border-color: #1A4069;
}
.top-works .swiper-button-prev:hover::after,
.top-works .swiper-button-next:hover::after {
  color: #ffffff;
}
.top-works .swiper-button-prev:after {
  content: "\e902";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.top-works .swiper-button-prev::after {
  transform: translate(-60%, -50%);
}
.top-works .swiper-button-next:after {
  content: "\e901";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.top-works .swiper-button-next::after {
  transform: translate(-40%, -50%);
}
.top-works .swiper-scrollbar {
  position: absolute;
  top: calc(100% + 2rem);
  width: 100%;
  height: 6px;
  z-index: 1;
  background: #C9CED2;
}
@media (min-width: 1176px) {
  .top-works .swiper-scrollbar {
    height: 8px;
    top: calc(100% + 3rem);
  }
}
.top-works .swiper-scrollbar-drag {
  background: #6398BE;
  height: 6px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}
@media (min-width: 768px) {
  .top-works .swiper-scrollbar-drag {
    height: 8px;
  }
}
.top-works .swiper-scrollbar-drag:hover {
  background-color: #1A4069;
}

/*--------------------------------------------------------------------/
	top topnews
/--------------------------------------------------------------------*/
.top-topnews {
  margin-bottom: clamp(1rem, 7vw, 7rem);
  padding: clamp(5rem, 9vw, 9rem) 0 clamp(4rem, 6vw, 6rem);
  background: #ffffff url(../img/top/new-bg.png) no-repeat;
  background-position: 100% 0;
  background-size: 50%;
  overflow: hidden;
}
@media (min-width: 768px) {
  .top-topnews {
    background-size: 38%;
  }
}
@media (min-width: 1176px) {
  .top-topnews {
    background-position: 0 100%;
    background-size: 30%;
  }
}
.top-topnews__inner {
  display: flex;
  min-height: 400px;
}
@media (min-width: 1176px) {
  .top-topnews__inner {
    column-gap: 3.55%;
  }
}
@media (max-width: 1175px) {
  .top-topnews__inner {
    flex-direction: column;
    row-gap: clamp(2rem, 3vw, 3rem);
  }
}
@media (min-width: 1176px) {
  .top-topnews__tit {
    width: 30.9%;
  }
}
.top-topnews__tit em {
  font-family: 'Red Hat Display', sans-serif;
  font-size: calc(1rem + 12 * (100vw - 280px) / 1360);
  font-weight: 600;
  letter-spacing: 0.18em;
  position: relative;
  font-style: normal;
  display: block;
  padding-top: 0.5em;
  color: #1A4069;
}
@media (min-width: 1540px) {
  .top-topnews__tit em {
    font-size: 1.33rem;
  }
}
.top-topnews__tit b {
  font-family: 'Zen Kaku Gothic Antique', sans-serif;
  font-size: calc(1.6rem + 24 * (100vw - 280px) / 1360);
  display: block;
  line-height: 1;
  font-weight: 700;
}
@media (min-width: 1540px) {
  .top-topnews__tit b {
    font-size: 2.78rem;
  }
}
.top-topnews__table {
  max-height: 570px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #C9CED2 #ffffff;
}
.top-topnews__table::-webkit-scrollbar {
  width: 8px;
}
.top-topnews__table::-webkit-scrollbar-track {
  background: #f6f6f6;
}
.top-topnews__table::-webkit-scrollbar-thumb {
  background: #C9CED2;
  border-radius: 8px;
  box-shadow: inset 0 0 0 1px #ffffff;
}
@media (min-width: 1176px) {
  .top-topnews__table {
    width: 65.55%;
  }
}
.top-topnews__table__inner {
  width: 100%;
}
.top-topnews__table__inner tr {
  border-bottom: 1px solid #C9CED2;
}
.top-topnews__table__inner tr:first-child {
  border-top: 1px solid #C9CED2;
}
.top-topnews__table__inner th {
  padding: 1.25em 0;
  text-align: left;
  letter-spacing: 0.02em;
  color: #1A4069;
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 500;
}
@media (min-width: 576px) {
  .top-topnews__table__inner th {
    width: 20%;
  }
}
@media (min-width: 1540px) {
  .top-topnews__table__inner th {
    font-size: 1.22rem;
  }
}
@media (max-width: 575px) {
  .top-topnews__table__inner th {
    width: 100%;
    display: block;
    padding: 1.25em 0 0 0;
    font-size: 0.94rem;
  }
}
.top-topnews__table__inner td {
  padding: 1.25em 0;
}
@media (min-width: 576px) {
  .top-topnews__table__inner td {
    width: auto;
  }
}
@media (max-width: 575px) {
  .top-topnews__table__inner td {
    width: 100%;
    display: block;
    padding: 0 0 1.25em 0;
  }
}

/*--------------------------------------------------------------------/
	top news
/--------------------------------------------------------------------*/
.top-news {
  margin-top: clamp(4rem, 9vw, 9rem);
  padding-top: clamp(6rem, 10vw, 10rem);
  padding-bottom: clamp(6rem, 10vw, 10rem);
  position: relative;
  overflow: hidden;
}
.top-news__inner {
  position: relative;
  z-index: 3;
}
.top-news__tit {
  color: #000000;
  margin-bottom: clamp(2rem, 3vw, 3rem);
}
.top-news__tit em {
  font-family: 'Red Hat Display', sans-serif;
  font-size: calc(3rem + 70 * (100vw - 280px) / 1360);
  font-weight: 500;
  padding-right: clamp(3rem, 6vw, 6rem);
  position: relative;
  font-style: normal;
  display: block;
}
@media (min-width: 1540px) {
  .top-news__tit em {
    font-size: 6.67rem;
  }
}
.top-news__tit b {
  font-family: 'Zen Kaku Gothic Antique', sans-serif;
  font-size: calc(1rem + 16 * (100vw - 280px) / 1360);
  display: block;
  font-weight: 700;
  color: #1A4069;
}
@media (min-width: 1540px) {
  .top-news__tit b {
    font-size: 2rem;
  }
}
.top-news__list .is-column {
  margin-bottom: clamp(2rem, 5vw, 5rem);
}
.top-news__list .is-col2 {
  margin-bottom: clamp(2rem, 5vw, 5rem);
  display: flex;
}
@media (min-width: 768px) {
  .top-news__list .is-col2 {
    flex-wrap: wrap;
    gap: 1.5em 2%;
  }
}
@media (min-width: 1540px) {
  .top-news__list .is-col2 {
    gap: 2em 3%;
  }
}
@media (max-width: 767px) {
  .top-news__list .is-col2 {
    flex-direction: column;
    row-gap: 1em;
  }
}
@media (max-width: 575px) {
  .top-news__list .is-col2 {
    row-gap: clamp(2em, 3vw, 3rem);
  }
}
@media (min-width: 768px) {
  .top-news__list .is-col2 > * {
    width: 49%;
  }
}
@media (min-width: 1540px) {
  .top-news__list .is-col2 > * {
    width: 48.5%;
  }
}
@media (max-width: 767px) {
  .top-news__list .is-col2 > * {
    width: 100%;
  }
}
.top-news__btn {
  margin-left: auto;
  margin-right: auto;
}
.top-news__btn a {
  display: block;
  text-align: center;
  position: relative;
  line-height: 1.4;
  transition: all 0.3s ease 0s;
  text-decoration: none !important;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  column-gap: 0.5em;
  align-items: center;
  color: #ffffff;
  background-color: #1A4069;
  border: 1px solid;
  border-color: #1A4069;
  padding: 1.18em 4%;
  width: 100%;
  border-radius: 999px;
}
@media (min-width: 1753px) {
  .top-news__btn a {
    font-size: 24px;
  }
}
.top-news__btn a:after {
  content: "\e901";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.top-news__btn a:hover {
  color: #ffffff;
  background-color: #6398BE;
  border-color: #6398BE;
}
.top-news__btn a:hover::after {
  color: #ffffff;
}
.top-news__btn a::before {
  content: "";
  justify-self: start;
}
.top-news__btn a::after {
  justify-self: end;
  font-size: 1.5em;
}
@media (max-width: 1175px) {
  .top-news__btn a {
    display: block;
    text-align: center;
    position: relative;
    line-height: 1.4;
    transition: all 0.3s ease 0s;
    text-decoration: none !important;
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    column-gap: 0.5em;
    align-items: center;
    color: #ffffff;
    background-color: #1A4069;
    border: 1px solid;
    border-color: #1A4069;
    padding: 1.18em 4%;
    width: 100%;
    border-radius: 999px;
  }
}
@media (max-width: 1175px) and (min-width: 1753px) {
  .top-news__btn a {
    font-size: 24px;
  }
}
@media (max-width: 1175px) {
  .top-news__btn a:after {
    content: "\e901";
    font-family: "icomoon" !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .top-news__btn a:hover {
    color: #ffffff;
    background-color: #6398BE;
    border-color: #6398BE;
  }
  .top-news__btn a:hover::after {
    color: #ffffff;
  }
  .top-news__btn a::before {
    content: "";
    justify-self: start;
  }
  .top-news__btn a::after {
    justify-self: end;
    font-size: 1.5em;
  }
}

.top-news__box {
  position: relative;
  background: #ffffff;
  text-align: left;
}
.top-news__box a {
  display: block;
  position: relative;
  text-decoration: none !important;
  height: 100%;
  transition: all 0.3s ease 0s;
  color: #000000;
  overflow: hidden;
}
.top-news__box a:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.top-news__box__inner {
  display: flex;
}
@media (min-width: 576px) {
  .top-news__box__inner {
    flex-direction: row-reverse;
  }
}
@media (max-width: 575px) {
  .top-news__box__inner {
    flex-direction: column-reverse;
  }
}
.top-news__box__img {
  width: 100%;
}
@media (min-width: 576px) {
  .top-news__box__img {
    width: 35.91%;
  }
}
@media (min-width: 768px) {
  .top-news__box__img {
    width: 25%;
  }
}
@media (min-width: 1540px) {
  .top-news__box__img {
    width: 240px;
  }
}
.top-news__box__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: 0;
}
.top-news__box__img__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}
.top-news__box__img__inner > div,
.top-news__box__img__inner figure,
.top-news__box__img__inner a,
.top-news__box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
@media (min-width: 576px) {
  .top-news__box__txt {
    width: 64.09%;
    padding: 0.75em 3.75%;
  }
}
@media (min-width: 768px) {
  .top-news__box__txt {
    width: 75%;
  }
}
@media (min-width: 1540px) {
  .top-news__box__txt {
    width: calc(100% - 240px);
  }
}
@media (max-width: 575px) {
  .top-news__box__txt {
    width: 100%;
    padding: 0.5em 5% 1.5em;
  }
}
.top-news__box__txt__inner {
  width: 100%;
}
.top-news__box__date {
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 500;
  letter-spacing: 0;
  color: #1A4069;
}
.top-news__box__category {
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.4em;
  row-gap: 0.2em;
}
.top-news__box__category .category-item {
  font-size: 0.7em;
}
.top-news__box__tit {
  font-size: calc(1rem + 3 * (100vw - 280px) / 1360);
  margin-bottom: 0.5em;
}
@media (min-width: 576px) {
  .top-news__box__tit {
    font-size: calc(1rem + 4 * (100vw - 576px) / 1064);
  }
}
@media (min-width: 768px) {
  .top-news__box__tit {
    font-size: calc(0.94rem + 4 * (100vw - 768px) / 872);
  }
}
@media (min-width: 1540px) {
  .top-news__box__tit {
    font-size: 1.17rem;
  }
}

.top-news {
  position: relative;
}
.top-news::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 80%;
  background: #F3FAFF;
  height: 100%;
}
.top-news .swiper {
  width: 100%;
  overflow: visible;
  position: relative;
  margin-bottom: 5rem;
}
@media (min-width: 1540px) {
  .top-news .swiper {
    margin-bottom: 7rem;
  }
}
.top-news .swiper-control {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 1440 px;
}
@media (min-width: 1921px) {
  .top-news .swiper-control {
    max-width: 75vw;
  }
}
.top-news .swiper-next_prev {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: calc(100% + 32px);
  left: -16px;
  top: 50%;
  z-index: 1;
  transform: translateY(-50%);
}
@media (min-width: 768px) {
  .top-news .swiper-next_prev {
    width: calc(100% + 64px);
    left: -32px;
  }
}
@media (min-width: 1176px) {
  .top-news .swiper-next_prev {
    width: calc(100% + 90px);
    left: -45px;
  }
}
.top-news .swiper-button-prev {
  left: 0;
}
.top-news .swiper-button-next {
  right: 0;
}
.top-news .swiper-button-prev,
.top-news .swiper-button-next {
  margin: 0;
  float: none;
  display: block;
  background-image: none;
  width: 48px;
  height: 48px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 3;
}
@media (min-width: 768px) {
  .top-news .swiper-button-prev,
  .top-news .swiper-button-next {
    width: 64px;
    height: 64px;
  }
}
@media (min-width: 1176px) {
  .top-news .swiper-button-prev,
  .top-news .swiper-button-next {
    width: 90px;
    height: 90px;
  }
}
.top-news .swiper-button-prev::before,
.top-news .swiper-button-next::before {
  content: "";
  position: absolute;
  background: transparent;
  border: 1px solid #1F56A9;
  transition: all 0.3s ease 0s;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.top-news .swiper-button-prev::after,
.top-news .swiper-button-next::after {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #1F56A9;
  font-size: 1.5em;
  transition: all 0.3s ease 0s;
  z-index: 3;
}
@media (min-width: 1176px) {
  .top-news .swiper-button-prev::after,
  .top-news .swiper-button-next::after {
    font-size: 2.67rem;
  }
}
.top-news .swiper-button-prev:hover::before,
.top-news .swiper-button-next:hover::before {
  background: #1A4069;
  border-color: #1A4069;
}
.top-news .swiper-button-prev:hover::after,
.top-news .swiper-button-next:hover::after {
  color: #ffffff;
}
.top-news .swiper-button-prev:after {
  content: "\e902";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.top-news .swiper-button-prev::after {
  transform: translate(-60%, -50%);
}
.top-news .swiper-button-next:after {
  content: "\e901";
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.top-news .swiper-button-next::after {
  transform: translate(-40%, -50%);
}
.top-news .swiper-scrollbar {
  position: absolute;
  top: calc(100% + 2rem);
  width: 100%;
  height: 6px;
  z-index: 1;
  background: #f6f6f6;
}
@media (min-width: 1176px) {
  .top-news .swiper-scrollbar {
    height: 8px;
    top: calc(100% + 3rem);
  }
}
.top-news .swiper-scrollbar-drag {
  background: #C9CED2;
  height: 6px;
  transition: all 0.3s ease 0s;
  cursor: pointer;
}
@media (min-width: 768px) {
  .top-news .swiper-scrollbar-drag {
    height: 8px;
  }
}
.top-news .swiper-scrollbar-drag:hover {
  background-color: #1A4069;
}

/*--------------------------------------------------------------------/
	blog
/--------------------------------------------------------------------*/
.top-blog {
  padding: clamp(6rem, 12vw, 12rem) 0;
  background: #ffffff;
  overflow: hidden;
}
.top-blog__tit {
  margin-bottom: clamp(3rem, 5vw, 5rem);
}
.top-blog__tit__inner {
  display: inline-block;
  position: relative;
  padding-right: clamp(2%, 3vw, 3rem);
  line-height: 1;
  vertical-align: baseline;
}
.top-blog__tit__inner::after {
  content: "";
  width: 100vw;
  height: 1px;
  background: #1A4069;
  position: absolute;
  left: 100%;
  bottom: 0.4em;
}
.top-blog__tit em {
  font-family: 'Red Hat Display', sans-serif;
  color: #000000;
  font-size: calc(3rem + 70 * (100vw - 280px) / 1360);
  font-weight: 500;
  padding-right: clamp(3rem, 6vw, 6rem);
  position: relative;
  font-style: normal;
  display: inline-block;
}
@media (min-width: 1540px) {
  .top-blog__tit em {
    font-size: 6.67rem;
  }
}
.top-blog__tit em::after {
  content: "";
  background: #C9CED2;
  position: absolute;
  right: 0.1em;
  bottom: 0.4em;
  height: 1px;
  width: 0.6em;
  height: 1px;
  transform-origin: bottom right;
  transform: rotate(-40deg);
}
.top-blog__tit b {
  font-family: 'Zen Kaku Gothic Antique', sans-serif;
  font-size: calc(1rem + 16 * (100vw - 280px) / 1360);
  color: #1A4069;
  font-weight: 700;
}
@media (min-width: 1540px) {
  .top-blog__tit b {
    font-size: 2rem;
  }
}
.top-blog .btn-wrap {
  margin-left: auto;
  margin-right: auto;
  margin-top: clamp(2rem, 5vw, 5rem);
}

.top-commonpost1 {
  width: 100%;
  display: flex;
  position: relative;
  z-index: 2;
}
@media (min-width: 768px) {
  .top-commonpost1 {
    column-gap: 6%;
  }
}
@media (min-width: 1540px) {
  .top-commonpost1 {
    column-gap: 10%;
  }
}
@media (max-width: 767px) {
  .top-commonpost1 {
    flex-direction: column;
    row-gap: 1em;
  }
}
@media (max-width: 575px) {
  .top-commonpost1 {
    row-gap: clamp(2em, 3vw, 3rem);
  }
}
@media (min-width: 768px) {
  .top-commonpost1::after {
    content: "";
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 46.66%;
    background: #E5E9EC;
  }
}
@media (min-width: 1540px) {
  .top-commonpost1::after {
    left: 46.66%;
  }
}

@media (min-width: 768px) {
  .top-commonpost1__newest {
    width: 43.66%;
  }
}
@media (min-width: 1540px) {
  .top-commonpost1__newest {
    width: 41.66%;
  }
}

.top-commonpost1__newest__box a {
  display: block;
  text-decoration: none !important;
  color: #000000;
  transition: all 0.3s ease 0s;
}
.top-commonpost1__newest__box a:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.top-commonpost1__newest__box__inner {
  display: flex;
  overflow: hidden;
}
@media (min-width: 768px) {
  .top-commonpost1__newest__box__inner {
    flex-direction: column-reverse;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .top-commonpost1__newest__box__inner {
    flex-direction: row-reverse;
  }
}
@media (max-width: 575px) {
  .top-commonpost1__newest__box__inner {
    flex-direction: column-reverse;
  }
}
.top-commonpost1__newest__box__img {
  width: 100%;
}
@media (min-width: 576px) and (max-width: 767px) {
  .top-commonpost1__newest__box__img {
    width: 35.91%;
  }
}
.top-commonpost1__newest__box__img__inner {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: 0;
}
.top-commonpost1__newest__box__img__inner::before {
  content: "";
  display: block;
  padding-top: 66.66%;
  position: relative;
}
.top-commonpost1__newest__box__img__inner > div,
.top-commonpost1__newest__box__img__inner figure,
.top-commonpost1__newest__box__img__inner a,
.top-commonpost1__newest__box__img__inner picture {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
@media (min-width: 768px) {
  .top-commonpost1__newest__box__txt {
    padding: 0.5em 1.5em 1.5em;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .top-commonpost1__newest__box__txt {
    width: 64.09%;
    padding: 0.75em 3.75%;
  }
}
@media (max-width: 575px) {
  .top-commonpost1__newest__box__txt {
    width: 100%;
    padding: 0.5em 5% 1.5em;
  }
}
.top-commonpost1__newest__box__date {
  font-family: 'Red Hat Display', sans-serif;
  letter-spacing: 0;
  font-weight: 500;
  color: #1A4069;
}
@media (min-width: 768px) {
  .top-commonpost1__newest__box__date {
    font-size: 1.15em;
  }
}
.top-commonpost1__newest__box__category {
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.4em;
}
@media (min-width: 768px) {
  .top-commonpost1__newest__box__category {
    margin-bottom: calc(0.5rem + 0.3em);
  }
}
.top-commonpost1__newest__box__category .category-item {
  font-size: 0.7em;
}
@media (min-width: 1540px) {
  .top-commonpost1__newest__box__category .category-item {
    font-size: 0.8em;
  }
}
.top-commonpost1__newest__box__tit {
  font-weight: 700;
  font-size: calc(0.94rem + 6 * (100vw - 280px) / 1360);
  margin-bottom: 0.5em;
}
@media (min-width: 768px) {
  .top-commonpost1__newest__box__tit {
    font-size: calc(0.9rem + 4 * (100vw - 768px) / 872);
  }
}
@media (min-width: 1540px) {
  .top-commonpost1__newest__box__tit {
    font-size: 1.11rem;
  }
}
@media (min-width: 768px) {
  .top-commonpost1__newest__box .txt {
    font-size: 0.9em;
  }
}
@media (min-width: 1540px) {
  .top-commonpost1__newest__box .txt {
    font-size: 0.89rem;
  }
}
@media (max-width: 767px) {
  .top-commonpost1__newest__box .txt {
    display: none;
  }
}

.top-commonpost1__list {
  display: flex;
  flex-direction: column;
}
@media (min-width: 768px) {
  .top-commonpost1__list {
    row-gap: 1em;
    width: 50.34%;
    justify-content: space-between;
  }
}
@media (min-width: 1540px) {
  .top-commonpost1__list {
    width: 48.34%;
  }
}
@media (max-width: 575px) {
  .top-commonpost1__list {
    row-gap: clamp(2em, 3vw, 3rem);
  }
}

.top-commonpost1__list__box {
  position: relative;
  text-align: left;
  width: 100%;
}
@media (min-width: 768px) {
  .top-commonpost1__list__box {
    height: 100%;
  }
}
.top-commonpost1__list__box a {
  display: block;
  position: relative;
  text-decoration: none !important;
  height: 100%;
  color: #000000;
  overflow: hidden;
  transition: all 0.3s ease 0s;
}
.top-commonpost1__list__box a:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.top-commonpost1__list__box__inner {
  display: flex;
}
@media (min-width: 576px) {
  .top-commonpost1__list__box__inner {
    flex-direction: row-reverse;
  }
}
@media (max-width: 575px) {
  .top-commonpost1__list__box__inner {
    flex-direction: column-reverse;
  }
}
.top-commonpost1__list__box__img {
  width: 100%;
}
@media (min-width: 576px) {
  .top-commonpost1__list__box__img {
    width: 35.91%;
  }
}
.top-commonpost1__list__box__img__inner {
  margin: 0;
}
@media (min-width: 576px) {
  .top-commonpost1__list__box__img__inner {
    height: 100%;
  }
  .top-commonpost1__list__box__img__inner > div {
    height: 100%;
  }
}
@media (max-width: 575px) {
  .top-commonpost1__list__box__img__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .top-commonpost1__list__box__img__inner::before {
    content: "";
    display: block;
    padding-top: 66.66%;
    position: relative;
  }
  .top-commonpost1__list__box__img__inner > div,
  .top-commonpost1__list__box__img__inner figure,
  .top-commonpost1__list__box__img__inner a,
  .top-commonpost1__list__box__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
@media (min-width: 576px) {
  .top-commonpost1__list__box__txt {
    width: 64.09%;
    padding: 0.75em 3.75%;
  }
}
@media (max-width: 575px) {
  .top-commonpost1__list__box__txt {
    width: 100%;
    padding: 0.5em 5% 1.5em;
  }
}
.top-commonpost1__list__box__date {
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 500;
  letter-spacing: 0;
  color: #1A4069;
}
.top-commonpost1__list__box__tit {
  margin-bottom: 0.5em;
}
@media (min-width: 768px) {
  .top-commonpost1__list__box__tit {
    font-size: calc(0.9rem + 2 * (100vw - 768px) / 872);
  }
}
@media (min-width: 1540px) {
  .top-commonpost1__list__box__tit {
    font-size: 0.89rem;
  }
}
.top-commonpost1__list__box__category {
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.4em;
  row-gap: 0.2em;
}
.top-commonpost1__list__box__category .category-item {
  font-size: 0.7em;
}

.top-commonpost2__list {
  display: flex;
}
@media (min-width: 768px) {
  .top-commonpost2__list {
    flex-wrap: wrap;
    gap: 1.5em 2%;
  }
}
@media (min-width: 1540px) {
  .top-commonpost2__list {
    gap: 2em 3%;
  }
}
@media (max-width: 767px) {
  .top-commonpost2__list {
    flex-direction: column;
    row-gap: 1em;
  }
}
@media (max-width: 575px) {
  .top-commonpost2__list {
    row-gap: clamp(2em, 3vw, 3rem);
  }
}
@media (min-width: 768px) {
  .top-commonpost2__list > * {
    width: 49%;
  }
}
@media (min-width: 1540px) {
  .top-commonpost2__list > * {
    width: 48.5%;
  }
}
@media (max-width: 767px) {
  .top-commonpost2__list > * {
    width: 100%;
  }
}

.top-commonpost2__box {
  position: relative;
  background: #ffffff;
  text-align: left;
}
.top-commonpost2__box a {
  display: block;
  position: relative;
  text-decoration: none !important;
  height: 100%;
  transition: all 0.3s ease 0s;
  color: #000000;
  overflow: hidden;
}
.top-commonpost2__box a:hover {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.top-commonpost2__box__inner {
  display: flex;
}
@media (min-width: 576px) {
  .top-commonpost2__box__inner {
    flex-direction: row-reverse;
  }
}
@media (max-width: 575px) {
  .top-commonpost2__box__inner {
    flex-direction: column-reverse;
  }
}
.top-commonpost2__box__img {
  width: 100%;
}
@media (min-width: 576px) {
  .top-commonpost2__box__img {
    width: 35.91%;
  }
}
@media (min-width: 768px) {
  .top-commonpost2__box__img {
    width: 25%;
  }
}
@media (min-width: 1540px) {
  .top-commonpost2__box__img {
    width: 240px;
  }
}
.top-commonpost2__box__img__inner {
  margin: 0;
}
@media (min-width: 576px) {
  .top-commonpost2__box__img__inner {
    height: 100%;
  }
  .top-commonpost2__box__img__inner > div {
    height: 100%;
  }
}
@media (max-width: 575px) {
  .top-commonpost2__box__img__inner {
    position: relative;
    width: 100%;
    z-index: 1;
  }
  .top-commonpost2__box__img__inner::before {
    content: "";
    display: block;
    padding-top: 66.66%;
    position: relative;
  }
  .top-commonpost2__box__img__inner > div,
  .top-commonpost2__box__img__inner figure,
  .top-commonpost2__box__img__inner a,
  .top-commonpost2__box__img__inner picture {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
}
@media (min-width: 576px) {
  .top-commonpost2__box__txt {
    width: 64.09%;
    padding: 0.75em 3.75%;
  }
}
@media (min-width: 768px) {
  .top-commonpost2__box__txt {
    width: 75%;
  }
}
@media (min-width: 1540px) {
  .top-commonpost2__box__txt {
    width: calc(100% - 240px);
  }
}
@media (max-width: 575px) {
  .top-commonpost2__box__txt {
    width: 100%;
    padding: 0.5em 5% 1.5em;
  }
}
.top-commonpost2__box__txt__inner {
  width: 100%;
}
.top-commonpost2__box__date {
  font-family: 'Red Hat Display', sans-serif;
  font-weight: 500;
  letter-spacing: 0;
  color: #1A4069;
}
.top-commonpost2__box__category {
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.4em;
  row-gap: 0.2em;
}
.top-commonpost2__box__category .category-item {
  font-size: 0.7em;
}
.top-commonpost2__box__tit {
  font-size: calc(1rem + 3 * (100vw - 280px) / 1360);
  margin-bottom: 0.5em;
}
@media (min-width: 576px) {
  .top-commonpost2__box__tit {
    font-size: calc(1rem + 4 * (100vw - 576px) / 1064);
  }
}
@media (min-width: 768px) {
  .top-commonpost2__box__tit {
    font-size: calc(0.94rem + 4 * (100vw - 768px) / 872);
  }
}
@media (min-width: 1540px) {
  .top-commonpost2__box__tit {
    font-size: 1.17rem;
  }
}