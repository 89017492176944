@charset "utf-8";
@use "_mixins/break-points" as *;
@use "base" as *;
/*====================================================================

index.css

=====================================================================*/
.main {
	overflow: hidden;
}

/*--------------------------------------------------------------------/
	main-view
/--------------------------------------------------------------------*/
$top_sp_hight: floor-decimal(calc(720 / 480) * 100) * 1vw; //スマホの高さ
$top_tb_hight: 57.1vw;
$top_pc_hight: 100vh;
.main-view {
	position: relative;
	@include media-breakpoint-up(md) {
		height: $top_tb_hight;
	}
	@include media-breakpoint-up(lg) {
		height: $top_pc_hight;
	}
	@include media-breakpoint-down(sm) {
		height: $top_sp_hight;
	}
}

.main-view__bg {
	position: relative;
	overflow: hidden;
	@include media-breakpoint-up(md) {
		height: $top_tb_hight;
	}
	@include media-breakpoint-up(lg) {
		height: $top_pc_hight;
	}
	@include media-breakpoint-down(sm) {
		height: $top_sp_hight;
	}
	&__inner {
		height: 100%;
	}
	&__img {
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: z-index(module, part01);
		@include media-breakpoint-up(md) {
			height: $top_tb_hight;
		}
		@include media-breakpoint-up(lg) {
			height: $top_pc_hight;
		}
		@include media-breakpoint-down(sm) {
			height: $top_sp_hight;
		}
		&__inner {
			height: 100%;
		}
	}
}

.main-view__inner {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: z-index(module, part02);
	& .l-cont {
		display: flex;
		align-items: center;
		height: 100%;
		justify-content: flex-end;
	}
}

.main-view__block {
	content: "";
	position: absolute;
	width: 100%;
	top: 0;
	right: 20%;
	z-index: z-index(module, part03);
	background: rgba($main_c, 0.9);
	width: 100vw;
	height: 130%;
	transform-origin: top right;
	transform: skew(-40deg);
	@include media-breakpoint-up(md) {
		width: 50vw;
		right: 54%;
	}
	@include media-breakpoint-up(lg) {
		background: rgba($main_c, 1);
	}
}

.main-view__catch {
	position: relative;
	z-index: z-index(module, part04);
	color: $black;
	padding-top: 7em;
	@include media-breakpoint-up(lg) {
		padding-top: 0;
		padding-right: 1rem;
	}
	.is-catch01,.is-catch02,.is-catch03 {
		display: block;
		margin-bottom: clamp(0.5rem, 1vw, 1rem);
		&__inner {
			display: inline-block;
			position: relative;
			@include line-h(1);
			@include f-family(font01);
			@include fs_xxs(22, 86);
			@include f-w(700);
			@include l-sp(0.07em);
			@include media-breakpoint-up(md) {
				@include fs_md(42, 72);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(86);
			}
			&.is-active {
				&::before {
					width: 100%;
				}
			}
			&::before {
				position: absolute;
				top: 0;
				left: 0;
				content: "";
				width: 0;
				height: 100%;
				background: $white;
				z-index: -1;
				@include transition;
			}
		}
		&__letter {
			display: inline-block;
			line-height: 1em;
		}
	}
	.is-catch04 {
		display: block;
		&__inner {
			display: inline-block;
			position: relative;
			padding-top: 0.5em;
			padding-left: 0.3em;
			@include line-h(1);
			@include f-w(700);
			@include f-family(font02);
			@include fs_xxs(10, 30);
			@include l-sp(0.12em);
			color: $main_c;
			@include media-breakpoint-up(md) {
				@include fs_md(14, 35);
			}
			@include media-breakpoint-up(full) {
				@include f-size(35);
			}
			&.is-active {
				&::before {
					width: 100%;
				}
			}
			&::before {
				position: absolute;
				top: 0;
				left: 0;
				content: "";
				width: 0;
				height: 100%;
				background: $white;
				z-index: -1;
				@include transition;
			}
		}
		&__letter {
			display: inline-block;
			line-height: 1em;
		}
	}
}

/* scroll */
.scroll-line {
	position: absolute;
	//left: unquote("min(2%,4rem)");
	left: min(1.5%, 3rem);
	bottom: 0;
	writing-mode: vertical-rl;
	z-index: z-index(module, part04);
	a {
		@include dec-none;
		@include f-family(font02);
		@include f-w(500);
		font-size: 0.9em;
		display: flex;
		align-items: center;
		gap: 0.5rem;
		color: $white;
		@include transition;
		&:hover {
			color: $hover_c;
		}
	}
	span {
		content: "";
		inline-size: 5rem;
		block-size: 1px;
		background: transparent;
		display: block;
		position: relative;
		overflow: hidden;
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			content: "";
			background: $white;
			@include transition;
			block-size: 1px;
			inline-size: 100%;
			animation: scroll-line 1.75s cubic-bezier(1, 0, 0, 1) infinite;
		}
	}
}

@keyframes scroll-line {
	0% {
		transform: scale(1, 0);
		transform-origin: 0 0;
	}
	50% {
		transform: scale(1, 1);
		transform-origin: 0 0;
	}
	51% {
		transform: scale(1, 1);
		transform-origin: 0 100%;
	}
	100% {
		transform: scale(1, 0);
		transform-origin: 0 100%;
	}
}
/* common
----------------------------------------------------------------*/

/*--------------------------------------------------------------------/
	contents
/--------------------------------------------------------------------*/
.contents {
	background: $white;
}

/*--------------------------------------------------------------------/
	aboutu us
/--------------------------------------------------------------------*/

// layout1
$top_aboutus_txt_pc: 55%; //テキスト　PC幅
$top_aboutus_img_pc: 44.43%; //画像　PC幅
$top_aboutus_between_pc: 100% - $top_aboutus_txt_pc - $top_aboutus_img_pc;
.top-aboutus {
	position: relative;
	overflow: hidden;
	padding-top: 8rem;
	padding-bottom: clamp(4rem, 8vw, 8rem);
	@include media-breakpoint-up(md) {
		padding-top: 12rem;
	}
	@include media-breakpoint-up(lg) {
		padding-top: clamp(4rem, 5vw, 5rem);
	}
	&__block {
		position: absolute;
		left: 0;
		bottom: 0;
		content: "";
		background: $bg_c1;
		@include media-breakpoint-up(lg) {
			width: 87.5%;
			height: calc(100% - 9.5vw);
		}
		@include media-breakpoint-up(xl) {
			height: calc(100% - 9em);
		}
		@include media-breakpoint-down(md) {
			width: 100%;
			height: calc(100% - 30vw);
		}
	}
	&__inner {
		@include m-a;
		max-width: 1536px;
		position: relative;
		display: flex;
		justify-content: space-between;
		z-index: z-index(module, part02);
		@include media-breakpoint-up(lg) {
			column-gap: $top_aboutus_between_pc;
			padding-left: map-get($contmargin, pc);
			padding-right: 7.22%;
		}

		@include media-breakpoint-up(full) {
			padding-right: 4%;
		}
		@include media-breakpoint-down(md) {
			flex-direction: column-reverse;
			row-gap: clamp(1.5em, 2vw, 2rem);
			padding-left: map-get($contmargin, tb);
			padding-right: map-get($contmargin, tb);
		}
		@include media-breakpoint-down(sm) {
			padding-left: map-get($contmargin, sp);
			padding-right: map-get($contmargin, sp);
		}
	}
}

.top-aboutus__txt {
	@include media-breakpoint-up(lg) {
		width: $top_aboutus_txt_pc;
		@include flex-end;
	}
	&__inner {
		@include media-breakpoint-up(xl) {
			padding-left: 0;
		}
		@include media-breakpoint-up(full) {
			width: 42.25vw;
		}
	}
	&__tit {
		margin-bottom: clamp(2rem, 3vw, 3rem);
		@include line-h(1.2);
		em {
			@include f-s_xxs(2, 70);
			display: block;
			font-style: normal;
			@include f-w(700);
			@include f-family(font02);
			color: $main_c;
			@include media-breakpoint-up(xxl) {
				@include f-size(100);
			}
		}
		b {
			@include f-s_xxs(1, 14);
			@include f-w(700);
			display: block;
			margin-top: 0.2em;
			@include media-breakpoint-up(xxl) {
				@include f-size(30);
			}
		}
	}
	&__txt {
		strong {
			@include f-w(700);
			font-size: 1.14em;
			@include line-h(1.5);
			@include media-breakpoint-up(md) {
				font-size: 1.25em;
			}
		}
		@include media-breakpoint-up(xl) {
			@include line-h(2.2);
			font-size: 1.075em;
		}
	}
}

.top-aboutus__img {
	position: relative;
	width: 60%;
	@include media-breakpoint-up(lg) {
		margin-top: 9.5em;
		max-width: 380px;
	}
	@include media-breakpoint-up(xl) {
		margin-top: 12.5em;
		max-width: 425px;
	}
	@include media-breakpoint-down(md) {
		max-width: 300px;
	}
	&__inner {
		@include aspect-img(4,3);
	}
	img {
		width: 100%;
		display: block;
	}
}
.top-aboutus__bg {
	position: absolute;
	top: 0;
	right: 0;
	z-index: z-index(module, part01);
	@include media-breakpoint-up(lg) {
		width: $top_aboutus_img_pc;
	}
	@include media-breakpoint-down(md) {
		width: 80%;
	}
	&__inner {
		@include aspect-img(853,387);
	}
	img {
		width: 100%;
		display: block;
	}
}

/*--------------------------------------------------------------------/
	pickup 01
/--------------------------------------------------------------------*/
.pickup01 {
	position: relative;
	padding-top: clamp(8rem, 16vw, 16rem);
	padding-bottom: clamp(6rem, 7vw, 7rem);
	margin-bottom: clamp(6rem, 10vw, 10rem);
	background: url(../img/top/pickup01-bg.png) no-repeat 50% 0;
	@include media-breakpoint-up(xl) {
		background-size: 100%;
	}
	&-tit {
		@include line-h(1);
		text-align: center;
		margin-bottom: clamp(3rem, 6vw, 6rem);
		em {
			display: block;
			margin-top: 1em;
			font-style: normal;
			@include f-s_xxs(1, 10);
			@include f-w(500);
			@include f-family(font02);
			@include l-sp(0.2em);
			color: $main_c;
			@include media-breakpoint-up(xxl) {
				@include f-size(24);
			}
		}
		b {
			display: block;
			@include f-s_xxs(1.5, 20);
			@include f-w(700);
			@include media-breakpoint-up(xl) {
				@include l-sp(0.075em);
				@include f-size(50);
			}
		}
	}
	&-inner {
		position: relative;
		z-index: z-index(module, part02);
	}
	&-btn {
		margin-top: clamp(4rem, 5vw, 5rem);
	}
	&::before {
		display: block;
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 80%;
		background-color: $bg_c2;
		content: "";
		z-index: z-index(module, part01);
		@include media-breakpoint-up(md) {
			height: 70%;
		}
		@include media-breakpoint-up(lg) {
			height: 55%;
		}
		@include media-breakpoint-up(xxl) {
			height: 50%;
		}
	}
}

.pickup01-intro {
	margin-bottom: clamp(5rem, 8vw, 8rem);
	&__flex {
		display: flex;
		@include media-breakpoint-up(lg) {
			justify-content: space-around;
			& > * {
				flex-basis: 40.5%;
			}
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
			row-gap: clamp(2rem, 3vw, 3rem);
		}
		.pickup01-intro__box__tit {
			@include media-breakpoint-up(lg) {
				@include f-s_lg(1.5, 10);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(36);
			}
		}
	}
}

.pickup01-intro__box {
	&__inner {
		width: 100%;
		padding-left: 10%;
		position: relative;
	}
	&__tit {
		@include f-w(700);
		padding-top: clamp(2rem, 4vw, 4rem);
		margin-bottom: calc(1rem + 0.5em);
		@include f-s_xxs(1, 24);
		@include media-breakpoint-up(xxl) {
			@include f-size(40);
		}
		&::before {
			position: absolute;
			top: 0;
			left: -1.25em;
			content: "";
			width: 4em;
			height: 1px;
			background: $main_c;
			transform-origin: top right;
			transform: rotate(-40deg);
		}
	}
}

// コンテンツボックスの最大横幅
$content_box_w: 400px;

.pickup01-list2 {
	display: flex;
	@include m-a;
	row-gap: clamp(3rem, 5vw, 5rem);
	@include media-breakpoint-up(sm) {
		flex-wrap: wrap;
		column-gap: 4%;
		max-width: calc((($content_box_w) * 2) + 4%);
	}
	@include media-breakpoint-up(md) {
		column-gap: 8%;
		max-width: calc((($content_box_w) * 2) + 8%);
	}
	@include media-breakpoint-up(lg) {
		column-gap: 4%;
		max-width: 100%;
		row-gap: clamp(3rem, 7vw, 7rem);
	}
	@include media-breakpoint-up(xl) {
		column-gap: 8%;
	}
	@include media-breakpoint-down(xs) {
		flex-direction: column;
	}
	& > * {
		@include media-breakpoint-up(sm) {
			width: 48%;
		}
		@include media-breakpoint-up(md) {
			width: 46%;
		}
		@include media-breakpoint-up(lg) {
			width: 48%;
		}
		@include media-breakpoint-up(xl) {
			width: 46%;
		}
	}
}


.pickup01-list3 {
	display: flex;
	@include m-a;
	row-gap: clamp(3rem, 5vw, 5rem);
	@include media-breakpoint-up(sm) {
		flex-wrap: wrap;
		column-gap: 4%;
	}
	@include media-breakpoint-up(md) {
		column-gap: 6%;
	}
	@include media-breakpoint-up(lg) {
		column-gap: 1.5%;
	}
	@include media-breakpoint-up(xl) {
		column-gap: 8.33%;
		max-width: 100%;
	}
	@include media-breakpoint-down(xs) {
		flex-direction: column;
	}
	& > * {
		@include media-breakpoint-up(sm) {
			width: 48%;
		}
		@include media-breakpoint-up(md) {
			width: 47%;
		}
		@include media-breakpoint-up(lg) {
			width: 32.33%;
		}
		@include media-breakpoint-up(xl) {
			width: 27.78%
		}
	}
}


.pickup01-list4 {
	display: flex;
	@include m-a;
	row-gap: clamp(3rem, 5vw, 5rem);
	@include media-breakpoint-up(sm) {
		flex-wrap: wrap;
		column-gap: 4%;
		max-width: calc((($content_box_w) * 2) + 4%);
	}
	@include media-breakpoint-up(md) {
		column-gap: 8%;
		max-width: calc((($content_box_w) * 2) + 8%);
	}
	@include media-breakpoint-up(xl) {
		column-gap: 3.48%;
		max-width: 100%;
	}
	@include media-breakpoint-down(xs) {
		flex-direction: column;
	}
	& > * {
		@include media-breakpoint-up(sm) {
			width: 48%;
		}
		@include media-breakpoint-up(md) {
			width: 46%;
		}
		@include media-breakpoint-up(xl) {
			width: 22.39%;
		}
	}
}

.pickup01-box {
	@include media-breakpoint-down(xxl) {
		max-width: $content_box_w;
	}
	&__inner {
		@include flex-column;
	}
	&__tit {
		order: 2;
		margin-top: 1.4em;
		line-height: 1.4;
		padding-bottom: calc(0.5rem + 0.3em);
		border-bottom: 1px solid $m-gray;
		position: relative;
		color: $main_c;
		@include f-w(700);
		@include f-s_xs(1.2, 1.5);
		@include media-breakpoint-up(md) {
			@include l-sp(0.02em);
		}
		@include media-breakpoint-up(lg) {
			@include l-sp(0.05em);
			@include f-s_lg(1, 3);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(22);
		}
		&::after {
			position: absolute;
			bottom: -1px;
			left: 0;
			content: "";
			background-color: $main_c;
			width: 60px;
			height: 1px;
		}
	}
	.pickup01-list4 &__tit {
		@include media-breakpoint-up(sm) {
			@include f-s_sm(1, 8);
		}
		@include media-breakpoint-up(xl) {
			@include f-s_xl(1.14, 2);
		}
		@include media-breakpoint-up(xxl) {
			@include f-size(20);
		}
	}
	&__img {
		order: 1;
		width: 100%;
		&__inner {
			@include aspect-img(16, 9);
			margin: 0;
		}
	}
	&__txt {
		order: 3;
		margin-top: 1em;
	}
	.btn-wrap {
		margin-top: 1.5em;
	}
	&__btn {
		@include btn_base;
		color: $white;
		background-color: $black;
		border: 1px solid;
		padding: 0.75em 4%;
		width: 100%;
		@include radius(999px);
		@include icon(arrow1_right, after);
		&:hover {
			background-color: $main_c;
		}
		&::after {
			position: absolute;
			right: 1rem;
			top: 50%;
			transform: translateY(-50%);
			color: $white;
		}
	}
}

/*--------------------------------------------------------------------/
	pickup 02
/--------------------------------------------------------------------*/
.pickup02 {
	&-wrap {
		position: relative;
		overflow: hidden;
		padding-top: clamp(2rem, 4vw, 4rem);
		margin-bottom: clamp(4rem, 7vw, 7rem);
		&::before {
			content: "";
			position: absolute;
			width: 80%;
			height: 74%;
			@include media-breakpoint-up(md) {
				height: 86%;
			}
			@include media-breakpoint-up(lg) {
				height: 90%;
			}
			@include media-breakpoint-up(xl) {
				height: 86%;
				width: 80.21%;
			}
		}
	}
	&-inner {
		position: relative;
		width: 100%;
		z-index: z-index(module, part02);
		padding: clamp(3rem, 7vw, 7rem) 0 clamp(4rem, 9vw, 9rem);
		@include media-breakpoint-up(lg) {
			padding: clamp(4rem, 7vw, 7rem) 0 clamp(4rem, 8vw, 8rem);
		}
		@include media-breakpoint-up(xl) {
			min-height: 900px;
		}
	}
	& > *:nth-of-type(odd) {
		.pickup02-wrap {
			&::before {
				top:0;
				left: 0;
				background: $main_c;
			}
		}
		.pickup02-img {
			right: 0;
			@include media-breakpoint-down(md) {
				@include flex-r-reverse;
			}
		}
		.pickup02-txt__tit {
			em {
				color: $main_c;
			}
			b {
				&::before {
					background: $main_c;
				}
			}
		}
		.pickup02-txt__catch {
			strong::before {
				background: $main_c;
			}
		}
		.pickup02-txt__btn {
			@include btn_arrow($white, $main_c, $main_c, $white, $sub_c, $sub_c);
		}
	}
	& > *:nth-of-type(even) {
		.pickup02-wrap {
			&::before {
				top: 0;
				right: 0;
				background: $hover_c;
			}
		}
		.pickup02-img {
			left: 0;
		}
		.pickup02-txt__tit {
			em {
				color: $hover_c;
			}
			b {
				&::before {
					background: $hover_c;
				}
			}
		}
		.pickup02-txt__catch {
			strong::before {
				background: $hover_c;
			}
		}
		.l-cont {
			@include flex-r-reverse;
		}
		.pickup02-txt__btn {
			@include btn_arrow($white, $hover_c, $hover_c, $white, $main_c, $main_c);
		}
	}
}

.pickup02-img {
	@include media-breakpoint-up(lg) {
		position: absolute;
		top: 0;
		width: 60%;
		height: 100%;
	}
	@include media-breakpoint-up(xl) {
		width: 44.79%;
	}
	&__wrap {
		@include media-breakpoint-up(lg) {
			width: 100%;
			height: 100%;
		}
		@include media-breakpoint-down(md) {
			width: 85%;
		}
	}
	&__inner {
		@include media-breakpoint-up(lg) {
			margin: 0;
			position: relative;
			width: 100%;
			height: 100%;
			div {
				width: 100%;
				height: 100%;
			}
		}
		@include media-breakpoint-down(md) {
			@include aspect-img;
		}
	}
}

.pickup02-txt {
	width: 100%;
	background: $white;
	position: relative;
	z-index: z-index(module, part02);
	overflow: hidden;
	@include media-breakpoint-up(md) {
		width: 90%;
	}
	@include media-breakpoint-up(lg) {
		width: 67%;
	}
	@include media-breakpoint-down(md) {
		margin-top: clamp(-3rem, -9vw, -8rem);
	}
	&__inner {
		padding: clamp(2em, 3vw, 3rem) 7% clamp(2em, 3vw, 3rem) 8%;
		@include media-breakpoint-up(xl) {
			padding: clamp(2em, 5vw, 5.5rem) 6% clamp(2em, 5vw, 5.5rem) 12%;
		}
		.txt {
			@include media-breakpoint-up(xxl) {
				@include f-size(19);
			}
		}
	}
	&__tit {
		@include line-h(1.2);
		margin-bottom: clamp(1em, 2vw, 2rem);
		em {
			@include f-family(font02);
			@include f-w(500);
			@include l-sp(0.12);
			font-style: normal;
			display: block;
			@include f-s_xxs(1, 16);
			margin-top: 0.4em;
			@include media-breakpoint-up(md) {
				@include f-s_md(1.5, 6);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(22);
			}
		}
		b {
			position: relative;
			@include f-family(font01);
			@include f-w(700);
			display: block;
			@include l-sp(0.075);
			@include f-s_xxs(1.45, 30);
			@include media-breakpoint-up(md) {
				@include f-s_md(2.2, 12);
			}
			@include media-breakpoint-up(xxl) {
				@include f-size(50);
			}
			&::before {
				position: absolute;
				top: 0.6em;
				right: calc(100% + 0.3em);
				content: "";
				width: 100%;
				height: 1px;
			}
		}
	}
	&__catch {
		margin-bottom: clamp(0.5em, 1vw, 0.75em);
		@include f-s_xxs(1, 6);
		@include line-h(1.5);
		@include media-breakpoint-up(md) {
			@include f-s_md(1, 4);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(20);
		}
		strong {
			position: relative;
			color: $txt_c;
			padding-right: 0.5em;
		}
	}
}

/*--------------------------------------------------------------------/
	top works
/--------------------------------------------------------------------*/
.top-works {
	margin-top: clamp(6rem, 13vw, 13rem);
	padding-top: clamp(4rem, 7vw, 7rem);
	padding-bottom: clamp(6rem, 10vw, 12rem);
	position: relative;
	overflow: hidden;
	&__bg {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 60%;
		max-height: 520px;
		z-index: z-index(module, part01);
		@include media-breakpoint-up(xl) {
			width: 98.5%;
		}
		@include media-breakpoint-up(xxl) {
			width: 95%;
		}
		&__inner {
			position: relative;
			max-height: 520px;
			overflow: hidden;
			&::after {
				position: absolute;
				top: 0;
				left: 0;
				content: "";
				width: 100%;
				height: 100%;
				background: rgba($black, 0.23);
				z-index: z-index(module, part02);
			}
		}
	}
	&__inner {
		position: relative;
		z-index: z-index(module, part03);
	}
	&__tit {
		color: $white;
		margin-bottom: clamp(2rem, 3vw, 3rem);
		em {
			@include f-family(font02);
			@include f-w(500);
			@include l-sp(0.3em);
			font-style: normal;
			display: block;
			@include f-s_xxs(1, 16);
			@include media-breakpoint-up(md) {
				@include f-s_md(1.5, 6);
			}
			@include media-breakpoint-up(xl) {
				@include f-size(32);
			}
		}
		b {
			@include f-family(font01);
			@include f-w(700);
			display: block;
			@include l-sp(0.075);
			@include f-s_xxs(1.8, 30);
			@include media-breakpoint-up(md) {
				@include f-s_md(2.2, 12);
			}
			@include media-breakpoint-up(xxl) {
				@include f-size(70);
			}
		}
	}
	&__list {
		.is-column {
			margin-bottom: clamp(2rem, 5vw, 5rem);
		}
	}
	&__btn {
		@include m-a;
		//@include media-breakpoint-up(lg) {
		//	position: absolute;
		//	top: 5rem;
		//	right: 0;
		//	width: 30%;
		//}
		a {
			@include btn_arrow($white, $main_c, $main_c, $white, $hover_c, $hover_c);
			//@include media-breakpoint-up(lg) {
			//	@include btn_arrow($white, transparent, $white, $white, $hover_c, $hover_c);
			//	border-width: 2px;
			//}
			@include media-breakpoint-down(md) {
				@include btn_arrow($white, $main_c, $main_c, $white, $hover_c, $hover_c);
			}
		}
	}
}

.top-works {
	position: relative;
	&::before {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		width: 80%;
		background: $p-gray;
		height: 80%;
		@include media-breakpoint-up(lg) {
			height: 70%;
			bottom: 0;
		}
	}
	// swiper custom
	.swiper {
		width: 100%;
		overflow: visible;
		position: relative;
		//padding-bottom: clamp(5rem, 8vw, 8rem);
		margin-bottom: 5rem;
		@include media-breakpoint-up(xl) {
			margin-bottom: 7rem;
		}
	}
	.swiper-control {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		max-width: map-get($grid-num, xl)px;
		@include media-breakpoint-up(full) {
			max-width: floor-decimal(calc(map-get($grid-num, xl) / map-get($grid-num,full)), 4) * 100vw
		}
	}
	.swiper-next_prev {
		position: relative;
		display: flex;
		justify-content: space-between;
		width: calc(100% + 32px);
		left: -16px;
		top: 40%;
		z-index: z-index(module, part01);
		transform: translateY(-50%);
		@include media-breakpoint-up(md) {
			width: calc(100% + 64px);
			left: -32px;
		}
		@include media-breakpoint-up(lg) {
			width: calc(100% + 90px);
			left: -45px;
		}
	}
	.swiper-button-prev {
		left: 0;
	}
	.swiper-button-next {
		right: 0;
	}
	.swiper-button-prev,
	.swiper-button-next {
		margin: 0;
		float: none;
		display: block;
		background-image: none;
		width: 48px;
		height: 48px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: z-index(module, part03);
		@include media-breakpoint-up(md) {
			width: 64px;
			height: 64px;
		}
		@include media-breakpoint-up(lg) {
			width: 90px;
			height: 90px;
		}
		&::before {
			content: "";
			position: absolute;
			background: $white;
			border: 1px solid $white;
			@include transition;
			width: 100%;
			height: 100%;
			@include radius(50%);
		}
		&::after {
			position: absolute;
			top: 50%;
			left: 50%;
			color: $main_c;
			font-size: 1.5em;
			@include transition;
			z-index: z-index(module, part03);
			@include media-breakpoint-up(lg) {
				@include f-size(48);
			}
		}
		&:hover {
			&::before {
				background: $main_c;
				border-color: $main_c;
			}
			&::after {
				color: $white;
			}
		}
	}
	.swiper-button-prev {
		@include icon(arrow2_left, after);
		&::after {
			transform: translate(-60%, -50%);
		}
	}
	.swiper-button-next {
		@include icon(arrow2_right, after);

		&::after {
			transform: translate(-40%, -50%);
		}
	}
	.swiper-scrollbar {
		position: absolute;
		top: calc(100% + 2rem);
		width: 100%;
		height: 6px;
		z-index: z-index(module, part01);
		background: $m-gray;
		@include media-breakpoint-up(lg) {
			height: 8px;
			top: calc(100% + 3rem);
		}
	}
	.swiper-scrollbar-drag {
		background: $hover_c;
		height: 6px;
		@include transition;
		cursor: pointer;
		@include media-breakpoint-up(md) {
			height: 8px;
		}
		&:hover {
			background-color: $main_c;
		}
	}
}

/*--------------------------------------------------------------------/
	top topnews
/--------------------------------------------------------------------*/
.top-topnews {
	margin-bottom: clamp(1rem, 7vw, 7rem);
	padding: clamp(5rem, 9vw, 9rem) 0  clamp(4rem, 6vw, 6rem);
	background: $white url(../img/top/new-bg.png) no-repeat;
	background-position: 100% 0;
	background-size: 50%;
	overflow: hidden;
	@include media-breakpoint-up(md) {
		background-size: 38%;
	}
	@include media-breakpoint-up(lg) {
		background-position: 0 100%;
		background-size: 30%;
	}
	&__inner {
		display: flex;
		min-height: 400px;
		@include media-breakpoint-up(lg) {
			column-gap: 3.55%;
		}
		@include media-breakpoint-down(md) {
			flex-direction: column;
			row-gap: clamp(2rem, 3vw, 3rem);
		}
	}
	&__tit {
		@include media-breakpoint-up(lg) {
			width: 30.9%;
		}
		em {
			@include f-family(font02);
			@include f-s_xxs(1, 12);
			@include f-w(600);
			@include l-sp(0.18em);
			position: relative;
			font-style: normal;
			display: block;
			padding-top: .5em;
			color: $main_c;
			@include media-breakpoint-up(xl) {
				@include f-size(24);
			}
		}
		b {
			@include f-family(font01);
			@include f-s_xxs(1.6, 24);
			display: block;
			@include line-h(1);
			@include f-w(700);
			@include media-breakpoint-up(xl) {
				@include f-size(50);
			}
		}
	}
	&__table {
		max-height: 570px;
		overflow-y: auto;
		@include scroll-bar;
		@include media-breakpoint-up(lg) {
			width: 65.55%;
		}
		&__inner {
			width: 100%;
			tr {
				border-bottom: 1px solid $m-gray;
				&:first-child {
					border-top: 1px solid $m-gray;
				}
			}
			th {
				padding: 1.25em 0;
				text-align: left;
				@include l-sp(0.02em);
				color: $main_c;
				@include f-family(font02);
				@include f-w(500);
				@include media-breakpoint-up(sm) {
					width: 20%;
				}
				@include media-breakpoint-up(xl) {
					@include f-size(22);
				}
				@include media-breakpoint-down(xs) {
					width: 100%;
					display: block;
					padding: 1.25em 0 0 0;
					@include f-size(17);
				}
			}
			td {
				padding: 1.25em 0;
				@include media-breakpoint-up(sm) {
					width: auto;
				}
				@include media-breakpoint-down(xs) {
					width: 100%;
					display: block;
					padding: 0 0 1.25em 0;
				}
			}
		}
	}
}

/*--------------------------------------------------------------------/
	top news
/--------------------------------------------------------------------*/
.top-news {
	margin-top: clamp(4rem, 9vw, 9rem);
	padding-top: clamp(6rem, 10vw, 10rem);
	padding-bottom: clamp(6rem, 10vw, 10rem);
	position: relative;
	overflow: hidden;
	&__inner {
		position: relative;
		z-index: z-index(module, part03);
	}
	&__tit {
		color: $txt_c;
		margin-bottom: clamp(2rem, 3vw, 3rem);
		em {
			@include f-family(font02);
			@include f-s_xxs(3, 70);
			@include f-w(500);
			padding-right: clamp(3rem, 6vw, 6rem);
			position: relative;
			font-style: normal;
			display: block;
			@include media-breakpoint-up(xl) {
				@include f-size(120);
			}
		}
		b {
			@include f-family(font01);
			@include f-s_xxs(1, 16);
			display: block;
			@include f-w(700);
			color: $main_c;
			@include media-breakpoint-up(xl) {
				@include f-size(36);
			}
		}
	}
	&__list {
		.is-column {
			margin-bottom: clamp(2rem, 5vw, 5rem);
		}
		.is-col2 {
			margin-bottom: clamp(2rem, 5vw, 5rem);
			display: flex;
			@include media-breakpoint-up(md) {
				flex-wrap: wrap;
				gap: 1.5em 2%;
			}
			@include media-breakpoint-up(xl) {
				gap: 2em 3%;
			}
			@include media-breakpoint-down(sm) {
				flex-direction: column;
				row-gap: 1em;
			}
			@include media-breakpoint-down(xs) {
				row-gap: clamp(2em, 3vw, 3rem);
			}
			& > * {
				@include media-breakpoint-up(md) {
					width: 49%;
				}
				@include media-breakpoint-up(xl) {
					width: 48.5%;
				}
				@include media-breakpoint-down(sm) {
					width: 100%;
				}
			}
		}
	}
	&__btn {
		@include m-a;
		//@include media-breakpoint-up(lg) {
		//	position: absolute;
		//	top: 5rem;
		//	right: 0;
		//	width: 30%;
		//}
		a {
			@include btn_arrow($white, $main_c, $main_c, $white, $hover_c, $hover_c);
			//@include media-breakpoint-up(lg) {
			//	@include btn_arrow($white, transparent, $white, $white, $hover_c, $hover_c);
			//	border-width: 2px;
			//}
			@include media-breakpoint-down(md) {
				@include btn_arrow($white, $main_c, $main_c, $white, $hover_c, $hover_c);
			}
		}
	}
}

.top-news__box {
	position: relative;
	background: $white;
	text-align: left;
	a {
		display: block;
		position: relative;
		@include dec-none;
		height: 100%;
		@include transition;
		color: $txt_c;
		overflow: hidden;
		&:hover {
			box-shadow: 0 0 10px rgba($black, 0.1);
		}
	}
	&__inner {
		display: flex;
		@include media-breakpoint-up(sm) {
			flex-direction: row-reverse;
		}
		@include media-breakpoint-down(xs) {
			flex-direction: column-reverse;
		}
	}
	&__img {
		width: 100%;
		@include media-breakpoint-up(sm) {
			width: 35.91%;
		}
		@include media-breakpoint-up(md) {
			width: 25%;
		}
		@include media-breakpoint-up(xl) {
			width: 240px;
		}
		&__inner {
			@include aspect-img;
			margin: 0;
		}
	}
	&__txt {
		@include media-breakpoint-up(sm) {
			width: 64.09%;
			padding: 0.75em 3.75%;
		}
		@include media-breakpoint-up(md) {
			width: 75%;
		}
		@include media-breakpoint-up(xl) {
			width: calc(100% - 240px);
		}
		@include media-breakpoint-down(xs) {
			width: 100%;
			padding: 0.5em 5% 1.5em;
		}
		&__inner {
			width: 100%;
		}
	}
	&__date {
		@include f-family(font02);
		@include f-w(500);
		@include l-sp(0);
		color: $main_c;
	}
	&__category {
		display: flex;
		flex-wrap: wrap;
		column-gap: 0.4em;
		row-gap: 0.2em;
		.category-item {
			font-size: 0.7em;
		}
	}
	&__tit {
		@include f-s_xxs(1, 3);
		margin-bottom: 0.5em;
		@include media-breakpoint-up(sm) {
			@include f-s_sm(1, 4);
		}
		@include media-breakpoint-up(md) {
			@include f-s_md(0.94, 4);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(21);
		}
	}
}


.top-news {
	position: relative;
	&::before {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		width: 80%;
		background: $bg_c2;
		height: 100%;
	}
	// swiper custom
	.swiper {
		width: 100%;
		overflow: visible;
		position: relative;
		//padding-bottom: clamp(5rem, 8vw, 8rem);
		margin-bottom: 5rem;
		@include media-breakpoint-up(xl) {
			margin-bottom: 7rem;
		}
	}
	.swiper-control {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		max-width: map-get($grid-num, xl)px;
		@include media-breakpoint-up(full) {
			max-width: floor-decimal(calc(map-get($grid-num, xl) / map-get($grid-num,full)), 4) * 100vw
		}
	}
	.swiper-next_prev {
		position: relative;
		display: flex;
		justify-content: space-between;
		width: calc(100% + 32px);
		left: -16px;
		top: 50%;
		z-index: z-index(module, part01);
		transform: translateY(-50%);
		@include media-breakpoint-up(md) {
			width: calc(100% + 64px);
			left: -32px;
		}
		@include media-breakpoint-up(lg) {
			width: calc(100% + 90px);
			left: -45px;
		}
	}
	.swiper-button-prev {
		left: 0;
	}
	.swiper-button-next {
		right: 0;
	}
	.swiper-button-prev,
	.swiper-button-next {
		margin: 0;
		float: none;
		display: block;
		background-image: none;
		width: 48px;
		height: 48px;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: z-index(module, part03);
		@include media-breakpoint-up(md) {
			width: 64px;
			height: 64px;
		}
		@include media-breakpoint-up(lg) {
			width: 90px;
			height: 90px;
		}
		&::before {
			content: "";
			position: absolute;
			background: transparent;
			border: 1px solid $other_c1;
			@include transition;
			width: 100%;
			height: 100%;
			@include radius(50%);
		}
		&::after {
			position: absolute;
			top: 50%;
			left: 50%;
			color: $other_c1;
			font-size: 1.5em;
			@include transition;
			z-index: z-index(module, part03);
			@include media-breakpoint-up(lg) {
				@include f-size(48);
			}
		}
		&:hover {
			&::before {
				background: $main_c;
				border-color: $main_c;
			}
			&::after {
				color: $white;
			}
		}
	}
	.swiper-button-prev {
		@include icon(arrow2_left, after);
		&::after {
			transform: translate(-60%, -50%);
		}
	}
	.swiper-button-next {
		@include icon(arrow2_right, after);

		&::after {
			transform: translate(-40%, -50%);
		}
	}
	.swiper-scrollbar {
		position: absolute;
		top: calc(100% + 2rem);
		width: 100%;
		height: 6px;
		z-index: z-index(module, part01);
		background: $p-gray;
		@include media-breakpoint-up(lg) {
			height: 8px;
			top: calc(100% + 3rem);
		}
	}
	.swiper-scrollbar-drag {
		background: $m-gray;
		height: 6px;
		@include transition;
		cursor: pointer;
		@include media-breakpoint-up(md) {
			height: 8px;
		}
		&:hover {
			background-color: $main_c;
		}
	}
}

/*--------------------------------------------------------------------/
	blog
/--------------------------------------------------------------------*/
.top-blog {
	padding: clamp(6rem, 12vw, 12rem) 0;
	background: $white;
	overflow: hidden;
	&__tit {
		margin-bottom: clamp(3rem, 5vw, 5rem);
		&__inner {
			display: inline-block;
			position: relative;
			padding-right: clamp(2%, 3vw, 3rem);
			@include line-h(1);
			vertical-align: baseline;
			&::after {
				content: "";
				width: 100vw;
				height: 1px;
				background: $main_c;
				position: absolute;
				left: 100%;
				bottom: 0.4em;
			}
		}
		em {
			@include f-family(font02);
			color: $black;
			@include f-s_xxs(3, 70);
			@include f-w(500);
			padding-right: clamp(3rem, 6vw, 6rem);
			position: relative;
			font-style: normal;
			display: inline-block;
			@include media-breakpoint-up(xl) {
				@include f-size(120);
			}
			&::after {
				content: "";
				background: $m-gray;
				position: absolute;
				right: 0.1em;
				bottom: 0.4em;
				height: 1px;
				width: 0.6em;
				height: 1px;
				transform-origin: bottom right;
				transform: rotate(-40deg);
			}
		}
		b {
			@include f-family(font01);
			@include f-s_xxs(1, 16);
			color: $main_c;
			@include f-w(700);
			@include media-breakpoint-up(xl) {
				@include f-size(36);
			}
		}
	}
	.btn-wrap {
		@include m-a;
		margin-top: clamp(2rem, 5vw, 5rem);
	}
}

// top-commonpost1
//--------------------------------------------------------------------*/

// layout1
$top_post1_newest_tb: 43.66%; //最新記事　タブレット幅
$top_post1_newest_pc: 41.66%; //最新記事　PC幅
$top_post1_list_tb: 50.34%; //記事リスト　タブレット幅
$top_post1_list_pc: 48.34%; //記事リスト　タブレット幅
$top_post1_between_tb: 100% - $top_post1_newest_tb - $top_post1_list_tb;
$top_post1_between_pc: 100% - $top_post1_newest_pc - $top_post1_list_pc;

.top-commonpost1 {
	width: 100%;
	display: flex;
	position: relative;
	z-index: z-index(module, part02);
	@include media-breakpoint-up(md) {
		column-gap: $top_post1_between_tb;
	}
	@include media-breakpoint-up(xl) {
		column-gap: $top_post1_between_pc;
	}
	@include media-breakpoint-down(sm) {
		flex-direction: column;
		row-gap: 1em;
	}
	@include media-breakpoint-down(xs) {
		row-gap: clamp(2em, 3vw, 3rem);
	}
	&::after {
		@include media-breakpoint-up(md) {
			content: "";
			width: 1px;
			height: 100%;
			position: absolute;
			top: 0;
			left: calc($top_post1_newest_tb + ($top_post1_between_tb / 2));
			background: $l-gray;
		}
		@include media-breakpoint-up(xl) {
			left: calc($top_post1_newest_pc + ($top_post1_between_pc / 2));
		}
	}
}

.top-commonpost1__newest {
	@include media-breakpoint-up(md) {
		width: $top_post1_newest_tb;
	}
	@include media-breakpoint-up(xl) {
		width: $top_post1_newest_pc;
	}
}

.top-commonpost1__newest__box {
	a {
		display: block;
		@include dec-none;
		color: $txt_c;
		@include transition;
		&:hover {
			box-shadow: 0 0 10px rgba($black, 0.1);
		}
	}
	&__inner {
		display: flex;
		overflow: hidden;
		@include media-breakpoint-up(md) {
			flex-direction: column-reverse;
		}
		@include media-breakpoint-only(sm) {
			flex-direction: row-reverse;
		}
		@include media-breakpoint-down(xs) {
			flex-direction: column-reverse;
		}
	}
	&__img {
		width: 100%;
		@include media-breakpoint-only(sm) {
			width: 35.91%;
		}
		&__inner {
			@include aspect-img;
			margin: 0;
		}
	}
	&__txt {
		@include media-breakpoint-up(md) {
			padding: 0.5em 1.5em 1.5em;
		}
		@include media-breakpoint-only(sm) {
			width: 64.09%;
			padding: 0.75em 3.75%;
		}
		@include media-breakpoint-down(xs) {
			width: 100%;
			padding: 0.5em 5% 1.5em;
		}
	}
	&__date {
		@include f-family(font02);
		@include l-sp(0);
		@include f-w(500);
		color: $main_c;
		@include media-breakpoint-up(md) {
			font-size: 1.15em;
		}
	}
	&__category {
		display: flex;
		flex-wrap: wrap;
		column-gap: 0.4em;
		@include media-breakpoint-up(md) {
			margin-bottom: calc(0.5rem + 0.3em);
		}
		.category-item {
			font-size: 0.7em;
			@include media-breakpoint-up(xl) {
				font-size: 0.8em;
			}
		}
	}
	&__tit {
		@include f-w(700);
		@include f-s_xxs(0.94, 6);
		margin-bottom: 0.5em;
		@include media-breakpoint-up(md) {
			@include f-s_md(0.9, 4);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(20);
		}
	}
	.txt {
		@include media-breakpoint-up(md) {
			font-size: 0.9em;
		}
		@include media-breakpoint-up(xl) {
			@include f-size(16);
		}
		@include media-breakpoint-down(sm) {
			display: none;
		}
	}
}

.top-commonpost1__list {
	@include flex-column;
	@include media-breakpoint-up(md) {
		row-gap: 1em;
		width: $top_post1_list_tb;
		justify-content: space-between;
	}
	@include media-breakpoint-up(xl) {
		width: $top_post1_list_pc;
	}
	@include media-breakpoint-down(xs) {
		row-gap: clamp(2em, 3vw, 3rem);
	}
}

.top-commonpost1__list__box {
	position: relative;
	text-align: left;
	width: 100%;
	@include media-breakpoint-up(md) {
		height: 100%;
	}
	a {
		display: block;
		position: relative;
		@include dec-none;
		height: 100%;
		color: $txt_c;
		overflow: hidden;
		@include transition;
		&:hover {
			box-shadow: 0 0 10px rgba($black, 0.1);
		}
	}
	&__inner {
		display: flex;
		@include media-breakpoint-up(sm) {
			flex-direction: row-reverse;
		}
		@include media-breakpoint-down(xs) {
			flex-direction: column-reverse;
		}
	}
	&__img {
		width: 100%;
		@include media-breakpoint-up(sm) {
			width: 35.91%;
		}
		&__inner {
			margin: 0;
			@include media-breakpoint-up(sm) {
				height: 100%;
				& > div {
					height: 100%;
				}
			}
			@include media-breakpoint-down(xs) {
				@include aspect-img;
			}
		}
	}
	&__txt {
		@include media-breakpoint-up(sm) {
			width: 64.09%;
			padding: 0.75em 3.75%;
		}
		@include media-breakpoint-down(xs) {
			width: 100%;
			padding: 0.5em 5% 1.5em;
		}
	}
	&__date {
		@include f-family(font02);
		@include f-w(500);
		@include l-sp(0);
		color: $main_c;
	}
	&__tit {
		margin-bottom: 0.5em;
		@include media-breakpoint-up(md) {
			@include f-s_md(0.9, 2);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(16);
		}
	}
	&__category {
		display: flex;
		flex-wrap: wrap;
		column-gap: 0.4em;
		row-gap: 0.2em;
		.category-item {
			font-size: 0.7em;
		}
	}
}

// top-commonpost2
//--------------------------------------------------------------------*/
.top-commonpost2 {
	&__list {
		display: flex;
		@include media-breakpoint-up(md) {
			flex-wrap: wrap;
			gap: 1.5em 2%;
		}
		@include media-breakpoint-up(xl) {
			gap: 2em 3%;
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			row-gap: 1em;
		}
		@include media-breakpoint-down(xs) {
			row-gap: clamp(2em, 3vw, 3rem);
		}
		& > * {
			@include media-breakpoint-up(md) {
				width: 49%;
			}
			@include media-breakpoint-up(xl) {
				width: 48.5%;
			}
			@include media-breakpoint-down(sm) {
				width: 100%;
			}
		}
	}
}

.top-commonpost2__box {
	position: relative;
	background: $white;
	text-align: left;
	a {
		display: block;
		position: relative;
		@include dec-none;
		height: 100%;
		@include transition;
		color: $txt_c;
		overflow: hidden;
		&:hover {
			box-shadow: 0 0 10px rgba($black, 0.1);
		}
	}
	&__inner {
		display: flex;
		@include media-breakpoint-up(sm) {
			flex-direction: row-reverse;
		}
		@include media-breakpoint-down(xs) {
			flex-direction: column-reverse;
		}
	}
	&__img {
		width: 100%;
		@include media-breakpoint-up(sm) {
			width: 35.91%;
		}
		@include media-breakpoint-up(md) {
			width: 25%;
		}
		@include media-breakpoint-up(xl) {
			width: 240px;
		}
		&__inner {
			margin: 0;
			@include media-breakpoint-up(sm) {
				height: 100%;
				& > div {
					height: 100%;
				}
			}
			@include media-breakpoint-down(xs) {
				@include aspect-img;
			}
		}
	}
	&__txt {
		@include media-breakpoint-up(sm) {
			width: 64.09%;
			padding: 0.75em 3.75%;
		}
		@include media-breakpoint-up(md) {
			width: 75%;
		}
		@include media-breakpoint-up(xl) {
			width: calc(100% - 240px);
		}
		@include media-breakpoint-down(xs) {
			width: 100%;
			padding: 0.5em 5% 1.5em;
		}
		&__inner {
			width: 100%;
		}
	}
	&__date {
		@include f-family(font02);
		@include f-w(500);
		@include l-sp(0);
		color: $main_c;
	}
	&__category {
		display: flex;
		flex-wrap: wrap;
		column-gap: 0.4em;
		row-gap: 0.2em;
		.category-item {
			font-size: 0.7em;
		}
	}
	&__tit {
		@include f-s_xxs(1, 3);
		margin-bottom: 0.5em;
		@include media-breakpoint-up(sm) {
			@include f-s_sm(1, 4);
		}
		@include media-breakpoint-up(md) {
			@include f-s_md(0.94, 4);
		}
		@include media-breakpoint-up(xl) {
			@include f-size(21);
		}
	}
}
